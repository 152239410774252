import React, { useEffect, useState } from 'react';
import { frontloadConnect } from 'react-frontload';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as actions from '../../../../store/actions/tridentApi';
import * as utils from '../../../../store/utils';
import { setGenericEvent } from '../../../../store/actions/dataLayer';

import DefaultLayout from '../../../../layouts/Default';
import BreadCrumb from '../../../../components/BreadCrumb';
import {
  META_TITLE,
  META_DESCRIPTION,
  BOAT_LOANS_CALCULATOR_URL,
} from '../../../../constants/BoatLoansCalculator';
import {
  BOAT_LOANS_URL,
  BOAT_LOAN_CALCULATOR_URL,
} from '../../../../constants/services';
import { Hero } from './components/Hero';
import { InfoAccordion } from './components/InfoAccordion';
import { BoatLoansProcess } from './components/BoatLoansProcess';
import { TrustPilotReviews } from './components/TrustPilotReviews';
import './styles.css';
import { getApplyNowLink } from '../../../../utils/trident';
import { PrivateSellerCalculatorFinanceable } from '../../../../components/PrivateSellerCalculatorFinanceable';
import { getBreakpoint } from '../../../../utils/commonHelper';

const BoatLoansCalculatorPageV2 = (props) => {
  const { tridentTeaserRate } = props;
  const canonical = `${process.env.REACT_APP_HOST}${BOAT_LOANS_CALCULATOR_URL}`;
  const [isMobile, setIsMobile] = useState(getBreakpoint() === 'mobile');

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    setIsMobile(getBreakpoint() === 'mobile');
  };

  const goToApplyNow = () => {
    const ctaLink = getApplyNowLink({ isVariant: false, utmCampaign: 'boatloancalculator', utmContent: 'boatloancalculator'});
    window.open(ctaLink, '_blank');
  };

  return (
    <>
      <DefaultLayout {...props} pageType="BoatLoans">
        <Helmet>
          <title>{META_TITLE}</title>
          <meta name="description" content={META_DESCRIPTION} />
          <link rel="canonical" href={canonical} />
        </Helmet>

        <div className="breadcrumb-container">
          <BreadCrumb
            items={[
              {
                title: 'Home',
                link: '/',
              },
              {
                title: 'Finance',
                link: BOAT_LOANS_URL,
              },
              {
                title: 'Boat Loan Calculator',
                link: BOAT_LOAN_CALCULATOR_URL,
              },
            ]}
          />
        </div>

        <Hero />

        <div className="main-section">
          <div className="calculator-container">
            <PrivateSellerCalculatorFinanceable
              setGenericEvent={props.actions.setGenericEvent}
              tridentTeaserRate={tridentTeaserRate}
              tridentTermInMonths={240}
              goToApplyNow={goToApplyNow}
              showRightPanel={true}
            />
          </div>
          <BoatLoansProcess />
          <TrustPilotReviews goToApplyNow={goToApplyNow} isMobile={isMobile}/>
        </div>
        <div className="boatloans-info">
          <InfoAccordion />
        </div>
      </DefaultLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  const teaserRate = Number(get(state, 'app.trident.rates[0].teaserRate', '').replace('%', ''));
  return {
    ...(teaserRate && { tridentTeaserRate: teaserRate })
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({setGenericEvent}, dispatch),
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(frontloadConnect(async ({ dispatch }) => {
  if (utils.isServer()) {
    return await dispatch(actions.getRatesFromTridentAPI());
  }
}, {
  onMount: true,
  onUpdate: true
})(BoatLoansCalculatorPageV2));

