import React, { PureComponent,
  // useState
} from 'react';
import CarouselDisplayer from '../CarouselDisplayer';
import CarouselModalHeader from '../CarouselModalHeader';
import DetailsContact from '../../containers/BoatDetails/DetailsContact';
import { Ad } from '@dmm/react-common-components';
import { isLaptopLResolution } from '../../utils/commonHelper';
import { MediaCarouselModal } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import './styles.css';


const Modal = ({ handleClose, show, children, device, showThumbs, setWasOpened }) => {
  const handleCloseModal = () => {
    setWasOpened(false);
    handleClose();
  };

  return (
    <MediaCarouselModal
      onAnimationEnd={ () => setWasOpened(true) }
      open={ show }
      onClose={ handleCloseModal }
      showCloseText={ device === 'desktop' }
    >
      <div className={classnames({ 'modal display-block modal-carousel': show }, { 'modal display-none': !show }, { 'showThumbs': showThumbs })}>
        <section className="modal-main-carousel">
          {children}
        </section>
      </div>
    </MediaCarouselModal>
  );
};


class CarouselModal extends PureComponent {
  state = {
    wasOpened: false
  }

  componentDidMount() {
    this.setState({});
  }

  setWasOpened = (wasOpened) => {
    this.setState(prev => ({ ...prev, wasOpened }));
  }

  render() {
    /* eslint-disable-next-line max-len */
    const { device, isLoading, listing, url, cookies, myboats, selectedCarouselItem, adParams, show, handleOpenContactForm, showMoreBoats, breakpoint, openImmersiveTourModal, openModalDealerLocator } = this.props;
    const { wasOpened } = this.state;
    return (
      <Modal
        setWasOpened={this.setWasOpened}
        show={this.props.show}
        handleClose={this.props.hideModal}
        device={device}
        showThumbs={true}>
        {this.props.show &&
          <>
            <CarouselModalHeader listing={listing} isLoading={isLoading}
              cookies={cookies} url={url} myboats={myboats} device={device} openModalDealerLocator={openModalDealerLocator}
              handleOpenContactForm={handleOpenContactForm} showMoreBoats={showMoreBoats} breakpoint={breakpoint} openImmersiveTourModal={openImmersiveTourModal}/>
            <div data-testid="carousel-display-modal" flex="column justify-center grow" className="carousel-display-wrapper">
              <div className="ad-wrapper">
                {wasOpened &&
                 isLaptopLResolution() &&
                <Ad {...adParams.carouselBox1Params}
                  targeting={{ refresh: adParams.carouselBox1Params.reloadAd.toString() }} />}
              </div>
              <CarouselDisplayer isModal={true} showThumbs={true} show={show} device={device}
                isLoading={isLoading} listing={listing} selectedItem={selectedCarouselItem}/>
              {device !== 'desktop' && <DetailsContact isLoading={isLoading} listing={listing} disableSellerInfo={true} toggle={true} />}
              {device === 'mobile' &&
                wasOpened &&
                <Ad {...adParams.mobileCarouselLeaderboardParams}
                  targeting={{refresh: adParams.mobileCarouselLeaderboardParams.reloadAd.toString()}}/>}
            </div>
          </>
        }
        {device !== 'mobile' &&
          wasOpened &&
            <Ad {...adParams.leaderboardModalParams}
              targeting={{refresh: adParams.leaderboardModalParams.reloadAd.toString()}}/>}
      </Modal>
    );
  }
}

export default CarouselModal;
