export const SERVICES_URL = '/services/';
export const SERVICES_URL_PATTERN = '/services/(.+)?';
export const WARRANTY_URL = '/services/boat-warranty';
export const INSURANCE_URL = '/services/boat-insurance';
export const SHIPPING_URL = '/services/boat-transport-shipping';
export const DOCUMENTATION_URL = '/services/boat-documentation/';
export const HISTORY_URL = '/services/boat-history';
export const RENTAL_URL = '/services/boat-rental/';
export const BOAT_LOANS_URL = '/boat-loans/';
export const BOAT_LOAN_CALCULATOR_URL = '/boat-loans/calculator/';

export const WARRANTY_SERVICE = 'warranty';
export const FINANCE_SERVICE = 'finance';
export const INSURANCE_SERVICE = 'insurance';
export const SHIPPING_SERVICE = 'transport';
export const DOCUMENTATION_SERVICE = 'documentation';
export const RENTAL_SERVICE = 'rental';

export const QUOTE_BUTTON = 'Request A Quote';
export const APPLY_NOW_BUTTON = 'Apply Now';
export const ESTIMATE_MY_RATE_BUTTON = 'Estimate My Rate';
export const LEARN_BUTTON = 'Learn More';

export const YACHT_CLOSER_URL = 'https://ycregistration.com/services';
export const CLICK_AND_BOAT_URL = 'https://www.clickandboat.com/us/?utm_source=boattrader&utm_medium=partnerships&utm_campaign=US_partnerships-boattrader';
export const APPLY_NOW_TRIDENT_URL = 'https://www.tridentfunding.com/apply/?purpose=Boat&utm_source=boattrader&utm_medium=internalreferral&utm_campaign=servicespage';
export const ESTIMATE_MY_RATE_TRIDENT_URL = 'https://www.tridentfunding.com/boat-loans/?utm_source=boattrader&utm_medium=internalreferral&utm_campaign=servicespage';

export const YACHTCLOSER_PROVIDER = 'yachtcloser';
export const TRIDENT_PROVIDER = 'trident';

export const TYPE_PORTAL_HEADER = 'x-type-portal';
export const TYPE_PORTAL_HEADER_VALUE = 'boattrader';
export const PREMIUM_PLACEMENT_TYPE = 'premium_placement';
