import classnames from 'classnames';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import DataLayer from '../../components/DataLayerV2';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { ENGINES_ID } from '../../constants/Engines';
import { BOAT_CLASS_ID } from '../../containers/BoatClass';
import { handleUserLogin, subscribeMarketingMyBoats } from '../../store/actions';
import useNoIndex from '../../utils/hooks/useNoIndex';
import { UNAVAILABILITY_REDIRECT_COOKIE, REDUCTION_REDIRECT_COOKIE } from '../../constants/boatDetails';
import {
  getCanonical,
  getLinkRelNext,
  getLinkRelPrev,
  getMetaDescription,
  getMetaTitle,
} from '../../utils/metaContentHelper';

const DefaultLayout = ({
  id,
  user,
  cookies,
  listing,
  noIndex,
  children,
  pageType,
  noAdsParam,
  statusCode,
  urlParsedParams,
  hideOverflow = false,
  ...props
}) => {
  const currentCookies = get(cookies, 'cookies', {});
  const [isSimilarBoatsPage] = useState(currentCookies[UNAVAILABILITY_REDIRECT_COOKIE] || currentCookies[REDUCTION_REDIRECT_COOKIE]);

  const makeModel = get(props, 'makeModel');
  const matchParams = get(props, 'match.params', {});
  const params = urlParsedParams ? urlParsedParams : matchParams;
  const searchCount = get(props, 'search.count', 28);
  const partyDetails = get(props, 'partyDetails');
  const linkRelPrevUrl = getLinkRelPrev(params, pageType);
  const linkRelNextUrl = getLinkRelNext(params, searchCount, pageType);
  const linkRelPrev = linkRelPrevUrl ? <link rel="prev" href={linkRelPrevUrl} /> : '';
  const linkRelNext = linkRelNextUrl ? <link rel="next" href={linkRelNextUrl} /> : '';
  const url = get(props, 'location.pathname', '');
  const preventIndexing = useNoIndex(url) || noAdsParam || noIndex;
  const linkSEO = preventIndexing ? <meta name="robots" content={url.includes('keyword') ? 'noindex, nofollow' : 'noindex'}></meta> : <link rel="canonical" href={getCanonical(pageType, params, listing, url)} />;
  const metaUrl = !preventIndexing ? <meta name="og:url" content={getCanonical(pageType, params, listing, url)} /> : '';
  params.seoMakeInfo = props.seoMakeInfo;
  const oemDetails = props.oemDetails;
  const metaTitle = getMetaTitle(pageType, params, searchCount, listing, makeModel, partyDetails, oemDetails);
  const descriptionParams = [ENGINES_ID, BOAT_CLASS_ID].includes(pageType) ? urlParsedParams : matchParams;
  const metaDescription = getMetaDescription(
    pageType,
    descriptionParams,
    searchCount,
    listing,
    makeModel,
    partyDetails,
    id,
    oemDetails,
    isSimilarBoatsPage
  );

  useEffect(() => {
    if (user) {
      props.handleUserLogin(cookies, user);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classnames('page-container', { 'hide-overflow': hideOverflow === true })} id={id}>
      {statusCode !== 500 ?
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="og:title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta name="og:description" content={metaDescription} />
          <meta name="nodeVersion" content="14" />
          {metaUrl}
          {linkSEO}
          {linkRelPrev}
          {linkRelNext}
        </Helmet> :
        null
      }
      <DataLayer pageType={pageType} oemDetails={props.oemDetails} />
      <Header
        user={user}
        cookies={cookies}
        location={props.location}
        id={JSON.stringify(props.myboats)}
        hideAuth={props.hideAuth}
        pageType={props.pageType}
        getLeaderBoard={props?.getLeaderBoard}
      />
      <div className={classnames('content nav-slide', {'extra-padding': pageType === 'BoatDetails', 'engines': pageType === 'Engines'})}>
        {children}
        <Footer subscribeMarketingMyBoats={subscribeMarketingMyBoats}/>
      </div>
    </div>
  );
};

export default withCookies(connect(
  (state) => ({
    user: state.app.user,
    myboats: state.app.myboats
  }),
  { handleUserLogin },
)(DefaultLayout));
