import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { CDN_BOAT_LOANS } from '../../constants/BoatLoansCalculator';
import { checkPreQualVariation } from '../../utils/trident';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../Collapsible';
import Loading from '../LoadingPlaceholder';
import './styles.css';
class BoatLoans extends PureComponent {

  state = {
    hidden: false,
    showPhoneNumber: false,
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.openComponent){
      this.setState({
        openComponent: this.props.open
      });
    }
  }

  removeEncoding = (text) => text.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

  showPhoneNumber = () => {
    this.setState(prevState => {
      return { showPhoneNumber: !prevState.showPhoneNumber };
    });
  };

  hideStar = (pos, score) => {
    let hide = false;

    if ((score < 2.1) || // no stars
    (score >= 2.1 && score < 3.1 && pos >= 4) || // 3 stars
    (score >= 3.1 && score < 4.1 && pos === 5)) { // 4 stars
      hide = true;
    }

    return hide;
  };

  renderOverallStars = (score) => {
    return Array.from(Array(5).keys())
      .map((index) => {
        if (!this.hideStar(index + 1, score)) {
          return <img alt="green review star" key={index} src={`${CDN_BOAT_LOANS}/green-star.svg`} width="15" height="15" />;
        }
        return;
      });
  };

  renderReviewStars = (review) => {
    return Array.from(Array(review.stars).keys())
      .map((index) => <img alt="orange review star" key={index} src={`${CDN_BOAT_LOANS}/orange-star.svg`} width="15" height="15" />);
  };

  renderTestimonials = (reviews) => {
    return reviews.map((review, i) =>
      <div className="review" key={i}>
        <div className="top-row">
          <div className="review-author">{review.author}</div>
          <div className="review-google-icon"><img alt="google icon" src={`${CDN_BOAT_LOANS}/google-icon.svg`} width="15" height="15" /></div>
        </div>
        <div className="review-stars">
          {this.renderReviewStars(review)}
        </div>
        <div className="review-text"><div dangerouslySetInnerHTML={{ __html: this.removeEncoding(review.review) }} /></div>
      </div>
    );
  }

  renderLoans(editorialContent) {
    const {
      description: mainDescription,
      reviewsSection: { score, totalReviews, reviews },
      ratesSection: { description: ratesDescription },
      learnMoreSection: { description: learnMoreDescription }
    } = editorialContent;

    const {
      breakpoint,
      counter,
      listing,
    } = this.props;

    const showPhoneNumber = this.state.showPhoneNumber;

    return (
      <CollapsibleContent open={this.state.openComponent} openCounter={counter} initialState="open" classOverride="details">
        <CollapsibleHeader>
            Boat Loans
        </CollapsibleHeader>
        <Collapsible type="no-pad">
          <div className="content-wrapper">
            <div className="loans-container">
              <div className="boat-loans-description section-bottom-border">
                <div dangerouslySetInnerHTML={{ __html: this.removeEncoding(mainDescription) }} />
                <div className="bl-desc-cta-buttons-row">
                  { breakpoint === 'desktop' && <div className="bl-desc-cta-button callus desktop" onClick={this.showPhoneNumber}>{showPhoneNumber ? '888-356-6005' : 'Call Us For Financing'}</div> }
                  { breakpoint !== 'desktop' && <div className="bl-desc-cta-button callus mobile"><a href="tel:888-356-6005">Call Us For Financing</a></div> }
                  <div className="bl-desc-cta-button applynow">
                    <Action
                      as="a"
                      onClick={(e) =>{
                        e.preventDefault();
                        checkPreQualVariation({utmCampaign: 'BDPboatloansection', utmContent: 'BDPboatloansection', listing});
                      }}
                      data-e2e="boat-loans-apply-now-btn"
                      data-reporting-click-internal-link-type="click"
                      data-reporting-click-internal-link-id="boat loans - apply now"
                      icon={<img alt="link arrow" src={`${CDN_BOAT_LOANS}/external-link.svg`} width="20" height="20" />}
                      label="Apply Now"
                    />
                  </div>
                </div>
              </div>
              <div className="boat-loans-reviews section-bottom-border">
                <div className="section-title">Reviews</div>
                <div className="section-body">
                  <div className="reviews-summary">
                    <div className="logos">
                      <div className="review-logo-boat"><img alt="boat logo" src={`${CDN_BOAT_LOANS}/boat-sm.svg`} /></div>
                      <div className="review-logo-bt-financing"><img alt="boat trader financing logo" src={`${CDN_BOAT_LOANS}/BT-Financing-logo.svg`} width="112" height="46" /></div>
                    </div>
                    <div className="review-rating">
                      <div className="review-overall-score">{score}</div>
                      <div className="review-overall-stars">{this.renderOverallStars(score)}</div>
                    </div>
                    <div className="review-total-count">{`Based on ${totalReviews} reviews`}</div>
                  </div>
                  <div className="testimonials">{this.renderTestimonials(reviews)}</div>
                </div>
              </div>
              <div className="boat-loans-rates section-bottom-border">
                <div className="section-title">Rates</div>
                <div dangerouslySetInnerHTML={{ __html: this.removeEncoding(ratesDescription) }} />
              </div>
              <div className="boat-loans-faqs custom">
                <div className="section-title">FAQs</div>
                <div dangerouslySetInnerHTML={{ __html: this.removeEncoding(learnMoreDescription) }} />
                <div className="powered-by">
                  <div className="text">Powered by</div>
                  <div className="logo"><img alt="trident logo" src={`${CDN_BOAT_LOANS}/trident-faq-logo.svg`} width="98" height="13"/></div>
                </div>
              </div>
            </div>
          </div>
        </Collapsible>
      </CollapsibleContent>
    );
  }

  renderLoading() {
    return (
      <>
        <Loading type="text" />
        <Loading type="text" />
        <Loading type="text" />
      </>
    );
  }

  render() {
    const {
      isLoading,
      listing,
      editorialContent
    } = this.props;

    return (
      <div className="boat-loans-wrapper" data-testid="boat-loans-section">
        <div id="boat-loans-anchor"></div>
        <div id="boat-loans" className={classnames('boat-loans', { 'hidden-boat-loans': this.state.hidden })}>
          {isLoading || !listing.id ?
            this.renderLoading()
            :
            editorialContent?.isValid ? this.renderLoans(editorialContent) : <div className="editorial-errors">{ editorialContent?.errors || 'Unspecified' }</div>
          }
        </div>
      </div>
    );
  }
}

BoatLoans.propTypes = {
  isLoading: PropTypes.bool,
  listing: PropTypes.object.isRequired,
  editorialContent: PropTypes.object,
  breakpoint: PropTypes.string,
  counter: PropTypes.number,
  open: PropTypes.string.isRequired,
};

export default BoatLoans;
