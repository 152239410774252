import {
  formatPrice,
  formatPriceWithDecimal,
  formatWithDecimal,
} from '@dmm/lib-common/lib/formatting';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { TRACKING_BOAT_LOANS_CALCULATOR_DETAILED_PAGE_NAME, TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SECTION, TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SUB_SECTION, TRACKING_BOAT_LOANS_PORTAL } from '../../constants/BoatLoansV2';
import { calculateMonthlyPrice, showCalculator } from '../../utils/trident';
import { CDN_BOAT_LOANS } from '../../constants/BoatLoansCalculator';
import './Calculator.styles.css';

export const PrivateSellerCalculatorFinanceable = ({
  goToApplyNow,
  setGenericEvent,
  tridentLoanAmount,
  tridentTeaserRate,
  tridentTermInMonths,
  listing,
  showRightPanel
}) => {
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const shouldShowRightPanel = showCalculator(listing, hideTridentFeatures) || showRightPanel;
  const [calculator, setCalculator] = useState({
    autoCalculate: false,
    showErrorOnCalculate: {
      loanAmountOrMonthlyPayment: false,
      loanTermYears: false,
      loanTermMonths: false,
      interestRate: false,
    },
    formFields: {
      purchasePrice: '$0.00',
      downPayment: '$0.00',
      downPaymentPercent: '20%',
      loanTermYears: '',
      loanTermMonths: '',
      interestRate: '',
    },
    result: {
      monthlyPayment: '$0.00',
      loanAmount: '',
    },
  });

  const {
    showErrorOnCalculate,
    formFields: {
      purchasePrice,
      downPayment,
      downPaymentPercent,
      loanTermYears,
      loanTermMonths,
      interestRate,
    },
    result: {
      monthlyPayment,
      loanAmount,
    },
  } = calculator;

  useEffect(() => {
    let priceAmount = '$0.00';
    let downPaymentAmountDefault = '$0.00';
    let termYears;
    let formattedRate;
    let termMonths;
    let autoCalc = false;
    let actualLoanAmount;

    if (tridentLoanAmount) {
      priceAmount = formatPriceWithDecimal(tridentLoanAmount, 'USD', 'en-US', 2);
      downPaymentAmountDefault = formatPriceWithDecimal(tridentLoanAmount * 0.2, 'USD', 'en-US', 2);
      actualLoanAmount = formatPriceWithDecimal(tridentLoanAmount * 0.8, 'USD', 'en-US', 2);
      termYears = String(tridentLoanAmount * 0.8 >= 50000 ? 20 : 15);
      autoCalc = true;
    }

    if (tridentTeaserRate) {
      formattedRate = `${formatWithDecimal(
        tridentTeaserRate,
        'en-US',
        2,
        true
      )}`;
    }

    if (tridentTermInMonths) {
      termMonths = String(tridentTermInMonths);
      if (!termYears) {
        termYears = String(tridentTermInMonths / 12);
      }
    }

    setCalculator((prevCalculator) => ({
      ...prevCalculator,
      autoCalculate: autoCalc,
      formFields: {
        ...prevCalculator.formFields,
        purchasePrice: priceAmount,
        downPayment: downPaymentAmountDefault,
        ...(formattedRate && { interestRate: formattedRate }),
        ...(termYears && { loanTermYears: termYears }),
        ...(termMonths && { loanTermMonths: termMonths })
      },
      result: {
        ...prevCalculator.result,
        loanAmount: actualLoanAmount || priceAmount,
      }
    }));
  }, [tridentLoanAmount, tridentTeaserRate, tridentTermInMonths]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calculator.autoCalculate && tridentTeaserRate && interestRate !== '') {
      const triggerAutoCalculate = async () => {
        await handleCalculate();
      };
      triggerAutoCalculate();
    }
  }, [calculator.autoCalculate, tridentTeaserRate, interestRate]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeLastOccurrence = (str, char) => {
    const lastIndexOfL = str.lastIndexOf(char);
    return str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
  };

  const validatedownPayment = (processedValue) =>{
    const purchasePriceNumber = Number(calculator.formFields.purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number((processedValue).replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber > purchasePriceNumber) {
      return calculator.formFields.purchasePrice;
    }
    return processedValue;
  };

  const handleLoanTermsChange = (updatedFormValues, name, processedValue) => {
    const isLoanTermYears = name === 'loanTermYears';
    const isLoanTermMonths = name === 'loanTermMonths';
    if (isLoanTermYears || isLoanTermMonths) {
      const targetField = isLoanTermYears ? 'loanTermMonths' : 'loanTermYears';

      const value = isLoanTermYears ?
        (parseInt(processedValue) * 12).toString()
        :
        (processedValue.length
          ? parseInt(parseInt(processedValue) % 12) <= 6
            ? Math.floor(parseInt(processedValue) / 12).toString()
            : Math.ceil(parseInt(processedValue) / 12).toString()
          : '');

      if (isLoanTermMonths && (value === '10' || value === '12' || value === '15' || value === '20')) {
        updatedFormValues = {
          ...updatedFormValues,
          formFields: {
            ...updatedFormValues.formFields,
            [targetField]: value,
          },
        };
      } else if (isLoanTermMonths) {
        updatedFormValues = {
          ...updatedFormValues,
          formFields: {
            ...updatedFormValues.formFields,
            [targetField]: 'custom'
          },
        };
      } else {
        updatedFormValues = {
          ...updatedFormValues,
          formFields: {
            ...updatedFormValues.formFields,
            [targetField]: value
          },
        };
      }
    }
    return updatedFormValues;
  };

  const handleDownPaymentChange = (updatedFormValues, name, processedValue) => {
    const isDownPaymentAmount = name === 'downPayment';
    const isDownPaymentPercent = name === 'downPaymentPercent';
    if (isDownPaymentAmount || isDownPaymentPercent) {
      const targetField = isDownPaymentAmount ? 'downPaymentPercent' : 'downPayment';

      const purchasePriceNumber = Number(updatedFormValues.formFields.purchasePrice.replace(/[^0-9.-]+/g, ''));
      const downPaymentNumberOrPercentage = Number(processedValue.replace(/[^0-9.-]+/g, ''));

      const value = isDownPaymentAmount
        ? `${(
          (downPaymentNumberOrPercentage / purchasePriceNumber) *
            100
        ).toFixed(4)}%`
        : formatPriceWithDecimal(
          (
            (downPaymentNumberOrPercentage / 100) *
              purchasePriceNumber
          ).toFixed(2),
          'USD',
          'en-US',
          2
        );

      if (isDownPaymentAmount && (value === '0.0000%' || value === '10.0000%' || value === '20.0000%' || value === '30.0000%')) {
        updatedFormValues = {
          ...updatedFormValues,
          formFields: {
            ...updatedFormValues.formFields,
            [targetField]: value.replace(/.0000/g, ''),
          },
        };
      } else if (isDownPaymentAmount) {
        updatedFormValues = {
          ...updatedFormValues,
          formFields: {
            ...updatedFormValues.formFields,
            [targetField]: 'custom'
          },
        };
      } else {
        updatedFormValues = {
          ...updatedFormValues,
          formFields: {
            ...updatedFormValues.formFields,
            [targetField]: value
          },
        };
      }
    }
    return updatedFormValues;
  };

  const setCalculatorFormValue = (name, value) => {
    let processedValue = value;
    let updatedDownPayment = null;
    let updatedDownPaymentPercent = null;

    if (name === 'purchasePrice' || name === 'downPayment' || name === 'downPaymentPercent') {
      processedValue = (value.match(/\./g) || []).length > 1 ? removeLastOccurrence(processedValue, '.') : processedValue;
      const decimalPart = processedValue.indexOf('.') > -1 ? processedValue.substring(processedValue.indexOf('.')) : '';

      if (name !== 'downPaymentPercent') {
        processedValue = formatPrice(
          processedValue.replace(/[^0-9.]/g, '').replace(/\..*/, ''),
          'USD',
          'en-US'
        ) + decimalPart.replace(/[^0-9.]/g, '');

        if (name === 'downPayment') {
          processedValue = validatedownPayment(processedValue);
        }
        if (!processedValue) {
          processedValue = '$0';
        }
      }

      if (name === 'purchasePrice') {
        if (calculator.formFields.downPaymentPercent !== 'custom') {
          updatedDownPayment = (Number(processedValue.replace(/[^0-9.]/g, '')) * Number(calculator.formFields.downPaymentPercent.replace(/[^0-9.]/g, ''))) / 100;
        } else {
          updatedDownPaymentPercent = '20%';
          updatedDownPayment = (Number(processedValue.replace(/[^0-9.]/g, '')) * Number(updatedDownPaymentPercent.replace(/[^0-9.]/g, ''))) / 100;
        }
      }
    } else if (name === 'interestRate') {
      const numOfPeriods = (value.match(/\./g) || []).length;
      processedValue =
        numOfPeriods > 1
          ? removeLastOccurrence(value, '.')
          : value.replace(/[^0-9.]/g, '');
    } else {
      processedValue = value.replace(/[^0-9]/g, '');
    }

    setCalculator((prevCalculator) => {
      let updatedFormValues = {
        ...prevCalculator,
        formFields: {
          ...prevCalculator.formFields,
          [name]: processedValue,
        },
      };
      if (updatedDownPayment) {
        updatedFormValues.formFields.downPayment = formatPrice(updatedDownPayment, 'USD', 'en-US');
      }
      if (updatedDownPaymentPercent) {
        updatedFormValues.formFields.downPaymentPercent = updatedDownPaymentPercent;
      }
      updatedFormValues = handleLoanTermsChange(updatedFormValues, name, processedValue);
      updatedFormValues = handleDownPaymentChange(updatedFormValues, name, processedValue);
      return updatedFormValues;
    });
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setCalculatorFormValue(name, value);
  };

  const handleBlurInterestRate = async (event) => {
    const { name, value } = event.target;

    const processedValue = `${formatWithDecimal(
      value.replace(/[^0-9.]/g, ''),
      'en-US',
      2,
      true
    )}%`;
    setCalculatorFormValue(name, processedValue);
    await handleCalculate();
  };

  const handleBlur = async (event) => {
    const { name, value } = event.target;
    let processedValue = value;

    if (name === 'downPayment' || name === 'purchasePrice') {
      processedValue = formatPriceWithDecimal(
        value.replace(/[^0-9.]/g, ''),
        'USD',
        'en-US',
        2
      );
    }

    setCalculator((prevCalculator) => ({
      ...prevCalculator,
      formFields: {
        ...prevCalculator.formFields,
        [name]: processedValue,
      },
    }));
    await handleCalculate();
  };

  const updateLoanAmount = (loanAmount) => {
    setCalculator((prevCalculator) => ({
      ...prevCalculator,
      result: {
        ...prevCalculator.result,
        loanAmount
      },
    }));
  };

  const updateDownPayment = (downPayment) => {
    setCalculator((prevCalculator) => ({
      ...prevCalculator,
      formFields: {
        ...prevCalculator.formFields,
        downPayment
      },
    }));
  };

  const handleCalculate = async () => {
    const blankLoanAmountOrMonthlyPayment = !purchasePrice?.replace(/ /g, '').length > 0;
    const blankLoanTermYears = !loanTermYears?.replace(/ /g, '').length > 0;
    const blankLoanTermMonths = !loanTermMonths?.replace(/ /g, '').length > 0;

    const formMissingFields =
      blankLoanAmountOrMonthlyPayment ||
      blankLoanTermYears ||
      blankLoanTermMonths;

    const updates = {
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: blankLoanAmountOrMonthlyPayment,
        loanTermYears: blankLoanTermYears,
        loanTermMonths: blankLoanTermMonths,
      },
    };

    if (!formMissingFields) {
      const rate = interestRate?.replace(/ /g, '').length === 0 ? 0 : interestRate;
      const term = loanTermMonths;
      const loanAmountOrMonthlyPayment = calculator?.result?.loanAmount;

      setGenericEvent('loan calculation', 'calculate - click', 'monthly payment');
      const monthlyPayment = calculateMonthlyPrice(rate, term, loanAmountOrMonthlyPayment, false);
      updates.autoCalculate = false;
      updates.result = {
        ...calculator.result,
        monthlyPayment,
        loanAmount: loanAmountOrMonthlyPayment,
      };
    }

    setCalculator((prevCalculator) => ({
      ...prevCalculator,
      ...updates,
    }));
  };

  const clearErrors = async () => {
    setCalculator((prevCalculator) => ({
      ...prevCalculator,
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: false,
        loanTermYears: false,
        loanTermMonths: false,
        interestRate: false,
      },
    }));
  };

  return (
    <div className="private-calc-fin-calculator-container">
      <div className="private-calc-fin-calculator-sub-container">
        <div className="private-calc-fin-calculator-body">
          <CalculatorForm
            handleInputChange={handleInputChange}
            handleBlurInterestRate={handleBlurInterestRate}
            handleBlur={handleBlur}
            updateDownPayment={updateDownPayment}
            clearErrors={clearErrors}
            purchasePrice={purchasePrice}
            downPayment={downPayment}
            downPaymentPercent={downPaymentPercent}
            interestRate={interestRate}
            loanTermYears={loanTermYears}
            loanTermMonths={loanTermMonths}
            tridentTeaserRate={tridentTeaserRate}
            showErrorOnCalculate={showErrorOnCalculate}
          />
          {shouldShowRightPanel && (
            <CalculatorSummary
              goToApplyNow={goToApplyNow}
              monthlyPayment={monthlyPayment}
              loanAmount={loanAmount}
              purchasePrice={purchasePrice}
              downPayment={downPayment}
              updateLoanAmount={updateLoanAmount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PrivateSellerCalculatorFinanceable.propTypes = {
  setGenericEvent: PropTypes.func.isRequired,
  tridentLoanAmount: PropTypes.number,
  tridentTeaserRate: PropTypes.number,
  tridentTermInMonths: PropTypes.number,
  goToApplyNow: PropTypes.func,
  showMonthlyCalculatorOnly: PropTypes.bool,
  showPreQualified: PropTypes.bool,
  showRightPanel: PropTypes.bool,
};

const CalculatorForm = ({
  handleInputChange,
  handleBlurInterestRate,
  handleBlur,
  clearErrors,
  updateDownPayment,
  purchasePrice,
  downPayment,
  downPaymentPercent,
  interestRate,
  loanTermYears,
  loanTermMonths,
  showErrorOnCalculate
}) => {

  useEffect(() => {
    const purchasePriceNumber = Number(purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number(downPayment.replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber >= purchasePriceNumber){
      downPaymentNumber = purchasePriceNumber;
      downPayment = formatPriceWithDecimal(downPayment, 'USD', 'en-US', 2); // eslint-disable-line react-hooks/exhaustive-deps
      updateDownPayment(downPayment);
    }
  }, [purchasePrice, downPayment, downPaymentPercent]);

  return (
    <div className="private-calc-fin-calculator-form">
      <form>
        <fieldset className="form-fieldset">
          <div className="calc-form-title">
          Calculate your payment
          </div>

          <div className="calc-form-field">
            <label className="calc-form-label" htmlFor="loan-amount-or-monthly-payment">
              Purchase Price
            </label>
            <input
              className="calc-form-data input"
              type="text"
              id="loan-amount-or-monthly-payment"
              name="purchasePrice"
              data-e2e="purchasePrice"
              placeholder="Enter purchase price"
              onChange={handleInputChange}
              onFocus={clearErrors}
              onBlur={handleBlur}
              value={purchasePrice}
              data-testid="loan-amount-or-payment-form"
            />
          </div>

          <div className="calc-form-down-payment-container">
            <div className="calc-form-field down-payment">
              <label className="calc-form-label" htmlFor="loan-downpayment">
                Down Payment Amount
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-downpayment"
                name="downPayment"
                data-e2e="loan-downpayment"
                placeholder="Enter Down payment"
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlur}
                value={downPayment}
                data-testid="loan-downpayment-form"
              />
            </div>

            <div className="calc-or-text-fin">Or</div>

            <div className="calc-form-field down-payment">
              <label className="calc-form-label" htmlFor="loan-downpayment-percent">
                Down Payment Percent
              </label>
              <select
                className="calc-form-data input"
                id="loan-downpayment-percent"
                name="downPaymentPercent"
                placeholder="Select down payment percentage"
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlur}
                value={downPaymentPercent}
                data-testid="loan-downpayment-percent-form"
              >
                <option value="custom" disabled>Custom</option>
                <option value="0%">0%</option>
                <option value="10%">10%</option>
                <option value="20%">20%</option>
                <option value="30%">30%</option>
              </select>
            </div>
          </div>
          <label className="calc-form-label interest-rate" data-e2e="interest-rate" htmlFor="interest-rate">
            Interest Rate % (APR)
          </label>
          <div className="calc-calculate-interest-row" data-e2e="calc-calculate-interest-row">
            <input
              className="calc-form-data input interest-rate"
              type="text"
              id="interest-rate"
              data-e2e="interestRate"
              name="interestRate"
              placeholder="Enter interest rate"
              onChange={handleInputChange}
              onFocus={clearErrors}
              onBlur={handleBlurInterestRate}
              value={interestRate}
              data-testid="interest-rate-form"
            />
          </div>
          <div
            className="calc-free-text-input-error interest-rate"
            data-testid="calculate-button-validation-error"
            data-e2e="calc-free-text-input-error-interest-rate"
            hidden={!showErrorOnCalculate.interestRate}
          >
            * Missing required field
          </div>

          <div className="calc-form-loan-term-container">
            <div className="calc-form-field loan-term">
              <label className="calc-form-label loan-amount-or-monthly-payment" htmlFor="loan-term-years">
                Loan Term in Years
              </label>
              <select
                className="calc-form-data input years"
                type="text"
                id="loan-term-years"
                name="loanTermYears"
                data-e2e="loanTermYears"
                placeholder="Enter term in Years"
                onChange={handleInputChange}
                onFocus={clearErrors}
                value={loanTermYears}
                onBlur={handleBlur}
                data-testid="loan-term-years-form"
              >
                <option value="custom" disabled>Custom</option>
                <option value="10">10 Years</option>
                <option value="12">12 Years</option>
                <option value="15">15 Years</option>
                <option value="20">20 Years</option>
              </select>
              <div data-e2e="calculate-button-validation-error" className={classNames('calc-free-text-input-error', {hidden: !showErrorOnCalculate.loanTermYears})}>
                * Missing required field
              </div>
            </div>

            <div className="calc-or-text-fin">Or</div>

            <div className="calc-form-field loan-term">
              <label className="calc-form-label" htmlFor="loan-term-months">
                Loan Term in Months
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-term-months"
                name="loanTermMonths"
                data-e2e="loanTermMonths"
                placeholder="Enter term in Months"
                onChange={handleInputChange}
                onFocus={clearErrors}
                value={loanTermMonths}
                onBlur={handleBlur}
                data-testid="loan-term-months-form"
              />
              <div data-e2e="calculate-button-validation-error" className={classNames('calc-free-text-input-error', {hidden: !showErrorOnCalculate.loanTermMonths})}>
                * Missing required field
              </div>
            </div>
          </div>

          <div className="calc-form-instructions">
            The calculated monthly payment above is based on the APR and the loan term and loan amount that you entered.
          </div>

        </fieldset>
      </form>
    </div>
  );
};

CalculatorForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleBlurInterestRate: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  purchasePrice: PropTypes.string,
  downPayment: PropTypes.string,
  downPaymentPercent: PropTypes.string,
  loanTermYears: PropTypes.string,
  loanTermMonths: PropTypes.string,

  showErrorOnCalculate: PropTypes.shape({
    loanAmountOrMonthlyPayment: PropTypes.bool.isRequired,
    loanTermYears: PropTypes.bool.isRequired,
    loanTermMonths: PropTypes.bool.isRequired,
    interestRate: PropTypes.bool.isRequired,
  }),
};

export const LeadSuccessNotification = (props) => {

  const onClose = () => {
    props.onClose();
  };

  return <div className="inline-prequalified-success" data-testid="inline-prequalified-success-toast">
    <div className="inline-prequalified-success-logo"></div>
    <div className="inline-prequalified-success-info">
      <div className="inline-prequalified-title">Your request has been sent!</div>
      A member of our Boat Trader financing team from Tr342:ident Funding will be in touch shortly.
    </div>
    <button className="modal-close" data-testid="inline-close-prequalified-success" onClick={() => onClose()}>
      <span className="visuallyhidden">Close</span>
    </button>
  </div>;
};

const CalculatorSummary = ({
  goToApplyNow,
  monthlyPayment,
  purchasePrice,
  downPayment,
  loanAmount,
  updateLoanAmount
}) => {
  const [mobileCTA, setMobileCTA] = useState(false);

  const trackPreQualify = () => {
    window.dataLayer.push(
      {
        'event': 'link_click',
        'action_type': 'click',
        'action_label': 'boat loan - getting started',
        'ga4': {
          page: {
            'detailed_page_name': TRACKING_BOAT_LOANS_CALCULATOR_DETAILED_PAGE_NAME,
            'section': TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SECTION,
            'subsection': TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SUB_SECTION,
            portal: TRACKING_BOAT_LOANS_PORTAL
          }
        }
      }
    );
  };

  useEffect(() => {
    const updateCTA = () => {
      if (window.innerWidth <= 991) {
        setMobileCTA(true);
      } else {
        setMobileCTA(false);
      }
    };

    updateCTA();
    window.addEventListener('resize', updateCTA);
    return () => {
      window.removeEventListener('resize', updateCTA);
    };
  }, []);

  useEffect(() => {
    const purchasePriceNumber = Number(purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number(downPayment.replace(/[^0-9.-]+/g, ''));
    const loanAmount = formatPriceWithDecimal(purchasePriceNumber - downPaymentNumber, 'USD', 'en-US', 2);
    updateLoanAmount(loanAmount);
  }, [purchasePrice, downPayment]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="calc-calculator-summary">
        <div className="calc-calculator-summary-upper">
          <div className="calc-montlhy-payment-title">Estimated Monthly Payment</div>
          <div className="calc-monthly-payment-container">
            <span className="calc-montlhy-payment-amount" data-e2e="summary-data-monthly-payment">{monthlyPayment}*</span><span className="per-month">/month</span>
          </div>
          <div className="calc-calculator-loan-amount">
            <span className="calc-loan-amount-label">Total Loan Amount: </span><span className="calc-loan-amount-value">{loanAmount}</span>
          </div>
        </div>
        <div className="calc-calculator-summary-lower">
          { mobileCTA &&
            <div className="summary-button-container summary-step">
              <button className="get-started-button btn-primary"
                onClick={(e) =>{
                  e.preventDefault();
                  trackPreQualify();
                  goToApplyNow();
                }}
              >
                Get pre-qualified
              </button>
            </div>
          }
          <div className="calc-calculator-summary-lower-title">Get pre-qualified in minutes</div>
          <div className="calc-calculator-summary-lower-text"><div className="blue-checkmark"><img alt="blue checkmark" src={`${CDN_BOAT_LOANS}/blue-checkmark.svg`} /></div><div className="calc-summary-text">We’ll compare over 15 lenders to get you the best rate & terms</div></div>
          <div className="calc-calculator-summary-lower-text"><div className="blue-checkmark"><img alt="blue checkmark" src={`${CDN_BOAT_LOANS}/blue-checkmark.svg`} /></div><div className="calc-summary-text">Customized financing to meet your needs</div></div>
          <div className="calc-calculator-summary-lower-text"><div className="blue-checkmark"><img alt="blue checkmark" src={`${CDN_BOAT_LOANS}/blue-checkmark.svg`} /></div><div className="calc-summary-text">Over 25 years of marine lending experience</div></div>
          { !mobileCTA &&
            <div className="summary-button-container summary-step">
              <button className="get-started-button btn-primary"
                onClick={(e) =>{
                  e.preventDefault();
                  trackPreQualify();
                  goToApplyNow();
                }}
              >
                Get pre-qualified
              </button>
            </div>
          }
        </div>
      </div>
    </>
  );
};

CalculatorSummary.propTypes = {
  goToApplyNow: PropTypes.func.isRequired,
  monthlyPayment: PropTypes.string.isRequired,
  loanAmount: PropTypes.string.isRequired,
  updateLoanAmount: PropTypes.func.isRequired,
  purchasePrice: PropTypes.string.isRequired,
  downPayment: PropTypes.string.isRequired,
};
