import React, { PureComponent } from 'react';
import * as utils from '../../utils/scrollTo';
import classnames from 'classnames';
import './styles.css';

class BackToTopButton extends PureComponent {
  state = { isUserScrolling: false};
  timerScroll = null;

  handleBackToTopButtonClick = event => {
    event.preventDefault();
    utils.scrollTo('#root', { behavior: 'smooth' });
  }

  handleScroll = () => {
    // Execute action when user has stopped scrolling: https://stackoverflow.com/questions/4620906/how-do-i-know-when-ive-stopped-scrolling
    if (this.timerScroll !== null) {
      clearTimeout(this.timerScroll);

      // If user scrolls show button
      if (window.scrollY !== 0) {
        this.setState(() => ({ isUserScrolling: true}));
      }
    }

    this.timerScroll = setTimeout(() => {
      // If user stops scrolling hide button
      this.fadeOut();
    }, 4000);
  }

  fadeOut = () => {
    this.setState(() => ({ isUserScrolling: false}));
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <a role="button" className={classnames('bttBtn', { 'show': this.state.isUserScrolling })} onClick={this.handleBackToTopButtonClick}>
        Back To Top
      </a>
    );
  }
}

export default BackToTopButton;
