import * as types from '../constants';
import * as httpClient from '../../utils/httpClient';
import get from 'lodash/get';
import { RATES_TRIDENT_API_URL } from '../../constants/BoatLoans';
import cache from 'memory-cache';

const cacheTime = 15 * 60 * 1000;

const setTridentRates = data => ({
  type: types.TRIDENT_SET_RATES,
  data
});

const setBoatLoansContent = data => ({
  type: types.TRIDENT_SET_BOAT_LOANS_CONTENT,
  data
});

export const getFicoScores = (tridentRates) => {
  const rate = tridentRates?.[0] || [];
  const ficoScores = Object.keys(rate).filter(key => {
    return key.match(/^\d+-\d+$/);
  });
  return ficoScores;
};
const setTridentLeadSubmitted = data => ({
  type: types.TRIDENT_LEAD_SUBMITTED,
  data
});

export const validateContent = (editorialApiPayload) => {
  const sendResponse = (isValid, message, editorialContent = null) => ({
    ...editorialContent,
    isValid: isValid,
    errors: message
  });

  const preProcess = (text) => (!text ? '' : text.replace(/ /g, ''));

  if (!editorialApiPayload?.records || !Array.isArray(editorialApiPayload.records) ||
    editorialApiPayload.records.length === 0 || editorialApiPayload.records.length > 1 ||
    !editorialApiPayload.records[0]?.content?.body || typeof (editorialApiPayload.records[0]?.content?.body) !== 'string') {
    return sendResponse(false, 'Editorial api call must return a single record with content.body string field');
  }

  let content = editorialApiPayload?.records[0]?.content?.body;

  try {
    content = JSON.parse(content);
  }
  catch (e) {
    return sendResponse(false, `Article content body is not valid JSON: ${e}`);
  }

  const {
    description = '',
    reviewsSection = {},
    ratesSection = {},
    learnMoreSection = {}
  } = content;

  const {
    reviews = [],
    score = null,
    totalReviews = null
  } = reviewsSection;

  const ratesDescription = ratesSection?.description;
  const learnMoreDescription = learnMoreSection?.description;
  let errors = '';
  let containsInvalidReview = false;

  errors += (Object.keys(reviewsSection).length === 0 && reviewsSection.constructor === Object) ? 'Entire reviews section is missing' : '';
  errors += (Object.keys(ratesSection).length === 0 && ratesSection.constructor === Object) ? 'Entire rates section is missing' : '';
  errors += (Object.keys(learnMoreSection).length === 0 && learnMoreSection.constructor === Object) ? 'Entire learn more section is missing' : '';
  errors += preProcess(description) === '' ? 'Main description is missing | ' : '';
  errors += preProcess(ratesDescription) === '' ? 'Rates description is missing | ' : '';
  errors += preProcess(learnMoreDescription) === '' ? 'FAQ description is missing | ' : '';
  errors += !score || isNaN(score) ? 'Reviews overall score is missing or invalid | ' : '';
  errors += !totalReviews || isNaN(totalReviews) ? 'Reviews total count is missing or invalid | ' : '';
  errors += !reviews || reviews.length === 0 ? 'Reviews are missing | ' : '';

  if (reviews.length) {
    containsInvalidReview = reviews.reduce((invalid, review) => {
      return invalid ||
        (!review?.author || review.author.replace(/ /g, '') === '') ||
        (!review?.review || review.review.replace(/ /g, '') === '') ||
        (!review?.stars || isNaN(review.stars) || review.stars > 5 || review.stars < 0);
    }, false);

    errors += containsInvalidReview ? 'One or more reviews are invalid (missing author, stars, text or stars is not a number) | ' : '';
  }

  const isValid =
    description && description.replace(/ /g, '') !== '' &&
    ratesDescription && ratesDescription.replace(/ /g, '') !== '' &&
    learnMoreDescription && learnMoreDescription.replace(/ /g, '') !== '' &&
    score && !isNaN(score) &&
    totalReviews && !isNaN(totalReviews) &&
    !containsInvalidReview &&
    reviews && reviews.length > 0;

  return sendResponse(!!isValid, errors, content);
};

export const getRatesFromTridentAPI = () => async (dispatch, _getState, http) => {
  let result;
  try {
    const cachedData = cache.get('rates');
    if (!cachedData) {
      const apiClient = http || httpClient.getHttpClient();
      const res = await apiClient.get(RATES_TRIDENT_API_URL);
      cache.put('rates', res.data, cacheTime);
      cache.put('rates-last-success', res.data);
      result = dispatch(setTridentRates(res.data));
    } else {
      result = dispatch(setTridentRates(cachedData));
    }
  } catch (err) {
    const data = get(err, 'response.data', err.toString());
    // eslint-disable-next-line no-console
    console.error(data.toString());
    const cachedLastSuccess = cache.get('rates-last-success');
    if (cachedLastSuccess) {
      result = dispatch(setTridentRates(cachedLastSuccess));
    }
  }

  return result;
};

export const getBoatLoansFromEditorialAPI = () => async (dispatch, _getState, http) => {
  let apiClient = http || httpClient.getSecureProxyHttpClient();
  try {
    let boatLoansEditorial = '/app/editorial/articles?contentCategory=boating&contentName=finance';
    const res = await apiClient.get(boatLoansEditorial);
    return dispatch(setBoatLoansContent(validateContent(res.data)));
  } catch (err) {
    const data = get(err, 'response.data', 'Unspecified');
    // eslint-disable-next-line no-console
    console.error(data.toString());
    return dispatch(setBoatLoansContent({
      trident: { boatLoansSection: { errors: `Editorial API call failed: ${data.toString()}`, isValid: false } }
    }));
  }
};

export const setSubmittedPreQualifyForm = () => dispatch => {
  dispatch(setTridentLeadSubmitted(true));
};
