import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { likeBoat, unlikeBoat } from '../../store/actions';
import { addLead, addSaveBoat } from '../../store/actions/dataLayer';
import * as utils from '../../store/utils';
import { BRANCHIO_CONTENT_TYPES, BRANCH_EVENT, branchIoEventsManager } from '../../utils/branchIoEventsManager';
import useBranchIoMetaTag from '../../utils/hooks/useBranchIoMetaTag';
import './styles.css';


const LikedBoat = ({ myboats, listingId, listing, imtId, discount, showText }) => {
  const dispatch = useDispatch();


  const [liked, setLiked] = useState(false);
  const [queueLikedBoats, setQueueLikedBoats] = useState([]);
  const userRef = useSelector((state) => get(state, 'app.userRef', null));

  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();

  const forwardToLogin = () => {
    userRef.current.onOpenModal();
  };

  const getQueuedBoatsFromLocalStorage = () => JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];

  const queueLikedBoat = (listingId) => {
    const queuedBoats = getQueuedBoatsFromLocalStorage();
    if (!queuedBoats.includes(listingId)) {
      queuedBoats.push(listingId);
      setLiked(true);
    } else {
      queuedBoats.splice(queuedBoats.indexOf(listingId), 1);
      setLiked(false);
    }
    localStorage.setItem('QUEUED_LIKED_BOATS', JSON.stringify(queuedBoats));
    window.dispatchEvent(new Event('storage'));
  };

  const getLikedState = ()=> {
    if (utils.isServer()) {
      return false;
    }
    if (myboats.requireLogin) {
      const queuedBoats = getQueuedBoatsFromLocalStorage();
      setQueueLikedBoats(queuedBoats);
      return queuedBoats.includes(listingId);
    }
    return myboats.listings
      ? (myboats.listings.filter(
        (boat) => { return parseInt(boat.listingId) === parseInt(listingId);}).length > 0
      )
      : false;
  };

  const updateLikedState = ()=>setLiked(getLikedState());

  const handleLocalStorageChange = () => {
    const queuedBoatsFromLocalStorage = getQueuedBoatsFromLocalStorage();
    if (queueLikedBoats.length !== queuedBoatsFromLocalStorage.length) {
      updateLikedState();
    }
  };

  useEffect(() => {
    updateLikedState();

    if (myboats.requireLogin) {
      window.addEventListener('storage', handleLocalStorageChange);
      return () => window.removeEventListener('storage', handleLocalStorageChange);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => updateLikedState(), [myboats]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleButton = () => {
    if (!liked) {
      fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.SAVE_BOAT);
    }

    if (myboats.requireLogin) {
      queueLikedBoat(listingId);
      forwardToLogin();
    } else {
      if (!liked) {
        setLiked(true);
        addBoatToMyBoats(listingId);
        dispatch(addLead(imtId, 'save boat'));
        addSaveBoat();
      } else {
        setLiked(false);
        removeBoatFromMyBoats(listingId);
      }
    }
    return false;
  };

  const addBoatToMyBoats = (listingId) => {
    let data = {listingId: listingId};
    dispatch(likeBoat(data));
    branchIoEventsManager(BRANCH_EVENT.LIKE_BOAT, {
      sku: get(listing, 'id', ''),
      product_name: get(listing, 'model', ''),
      product_brand: get(listing, 'validMake', ''),
    });
  };

  const removeBoatFromMyBoats = (listingId) => {
    dispatch(unlikeBoat(listingId));
  };

  const hiddenText = liked ? 'Saved' : 'Save';
  return (
    <>
      <BranchIoMetaTagComponent/>
      <Action
        data-testid="liked-boat-btn"
        data-e2e="heart-icon-btn"
        className={classnames('heart', { 'liked': liked }, { 'discount': discount} )}
        onClick={toggleButton}
        label={showText ? hiddenText : ''}
        aria-label={liked ? 'Unlike Boat' : 'Like Boat'}
      />
    </>
  );
};

export default LikedBoat;
