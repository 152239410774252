import {
  isSegmentCandidateForFinance,
  isSegmentCandidateForBrokerDealer,
  isSegmentCandidateForFinanceAdvantage,
} from '../../../utils/trident';
import { FEATURE_KEYS, findFeatureFlagActive } from '../../experiment/kameleoonHelper';

const isSegmentCandidate = (listing) => {
  return (
    isSegmentCandidateForFinance(listing) &&
    isSegmentCandidateForBrokerDealer(listing) &&
    isSegmentCandidateForFinanceAdvantage(listing)
  );
};

const isActive = (abTestConfig, listing) => {
  const featureFlag = findFeatureFlagActive(abTestConfig, FEATURE_KEYS.BDP_INTERESTED_IN_FINANCE_CHECKBOX);
  return featureFlag?.variation === 'on' && isSegmentCandidate(listing);
};

export const interestedInFinanceCheckboxExperiment = {
  isActive,
  isSegmentCandidate
};
