import React from 'react';
import { OEMInfoPanel } from '@dmm/lib-react-ui-components';
import { BOAT_LEAD_SERVICES_TYPE, TRACKING_EVENT_LEAD_SERVICES_SUBMISSION } from '../../../constants/BoatLoans';
import { getApplyNowLink } from '../../../utils/trident';

const BoatLoansServices = ({ listing, showModalPreQualified }) => {
  const applyNowLink = getApplyNowLink({ isVariant: true, utmCampaign: 'BDPOem', utmContent: 'OEMInfoPanel', listing });

  return (
    <div className="boat-loans-services-wrapper">
      <section className="boat-loans-services-section">
        <OEMInfoPanel
          bgUrl={'https://www.boattrader.com/static/img/get-financing-bg.png'}
          title={'Get Financing'}
          infoText={'Make sure your offer is ready when you are. With flexible and easy to secure boat loans, owning a boat has never been more affordable, working with our team gets you on the water faster.'}
          button={{
            label: 'Apply Now',
            onClick: /* istanbul ignore next */ () => {
              window.location.href = applyNowLink;
            }
          }}
          classNames={{
            light: true
          }}
        />
        <OEMInfoPanel
          bgUrl={'https://www.qa.boattrader.com/static/img/boat-protection-bg.png'}
          title={'24/7 Boat Protection'}
          infoText={'Boat protection services let you cruise with confidence for as low as $999. Get trailer protection, GAP coverage, 24/7 emergency support, and priority dealership service appointments on any boat, any time.'}
          button={{
            label: 'Get Protected',
            onClick: () => {
              showModalPreQualified(true, BOAT_LEAD_SERVICES_TYPE, 'BDP', {'event': TRACKING_EVENT_LEAD_SERVICES_SUBMISSION});
            }
          }}
          classNames={{
            dark: true
          }}
        />
      </section>
    </div>
  );
};

export default BoatLoansServices;
