import React, {lazy, Suspense} from 'react';
import {LISTING_ENHANCED} from '../../constants/boats';
import get from 'lodash/get';

const ContactForm = lazy(() => import('../ContactForm'));

const renderLoader = () => <div className="preloader" data-testid="preloader"></div>;

const ListingContactForm = ({type, title, subtitle, listing, hideComments, comment, onClose, open, analytics = true}) => {
  if (!open) {
    return null;
  }
  let listingType = LISTING_ENHANCED;
  let contactData = {
    boatClass: listing?.class,
    make: listing?.make,
    length: get(listing, 'specifications.dimensions.lengths.nominal.ft', 0),
    state: listing?.location?.address?.state,
    listingType
  };

  return (
    <Suspense fallback={renderLoader()}>
      <ContactForm
        hideComments={hideComments}
        title={title}
        subtitle={subtitle}
        key={listing.id}
        open={true}
        emailLead={analytics}
        listingId={listing.id}
        data={contactData}
        prePopulatedText={comment}
        onClose={onClose}
        type={type}
        listing={listing}
        contactSubmitTrack={true}
      />
    </Suspense>
  );
};

export default ListingContactForm;
