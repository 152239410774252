import React from 'react';
import './styles.css';
import DefaultLayout from '../../../layouts/Default';
import BreadCrumb from '../../../components/BreadCrumb';
import DealerList from '../../../components/DealerList';
import Pagination from '../../../components/Pagination';
import { getDealerSearchUrl, getDealersSRPResultsText, getDealersSRPHeader } from '../../../utils/urlHelpers/boatDealers';
import { getDealersSRPBreadCrumb} from '../../../utils/dealerSRPHelper';
import get from 'lodash/get';
import DealerLocations from '../../../components/DealerLocations';
import DealerMakes from '../../../components/DealerMakes';
import DealerRelatedArticles from '../../../components/DealerRelatedArticles';
import { US_STATES } from '../../../constants/boats';
import { BOAT_DEALER_BASE_URL } from '../../../constants/BoatDealers';
import DealerFilters from '../../../components/DealerList/DealerFilters';
import Spinner from '../../../components/Spinner';
import AdProvider from '../../../components/Ads/AdProvider';
import {DealerAd, dealersAdsConfig} from '../resources/dealerAds';
import { faqContent } from '../resources/dealerFaqs';
import ExpansibleInfoTable from '../../../components/ExpansibleInfoTable';
import { getFAQSchema } from '../../../utils/metaContentHelper';
import RichSnippet from '../../../components/RichSnippet';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MainContent = (props) => {
  const getPaginationUrl = page => {
    const params = get(props, 'params', {});
    return getDealerSearchUrl({ ...params, page });
  };

  const {count, records, cities, states, params, brands, zips, isWorking, history, topMakes, allStates, seoMakeInfo} = props;
  const loading = isWorking;
  const stateObj = params.countrySubDivisionCode ? US_STATES.find( item => item.value === params.countrySubDivisionCode.toUpperCase()) : '';
  const state = stateObj ? stateObj.name : params.countrySubDivisionCode;
  let countrySubDivisionCode = params.countrySubDivisionCode;
  let city = params.city;
  let locations;
  if (records.length) {
    if (city) {
      locations = zips;
    } else if (countrySubDivisionCode) {
      locations = cities;
    } else {
      locations = states;
    }
  } else {
    locations = allStates;
    countrySubDivisionCode = null;
    city = null;
  }
  const paramsWithStateName = state ? {...params, countrySubDivisionCode: state, seoMakeInfo} : {...params, seoMakeInfo};
  const breadCrumbs = getDealersSRPBreadCrumb(paramsWithStateName);
  const SRPHeader = getDealersSRPHeader(paramsWithStateName);
  const resultsText = count > 0 ? getDealersSRPResultsText(paramsWithStateName, count) : '';
  const id = !isWorking ? 'boatDealers' : 'boatDealersNoFooter';
  const showAdds = records.length > 5;
  const isLandingPage = (getPaginationUrl(params.page) === BOAT_DEALER_BASE_URL);
  const faqsSchema = isLandingPage ? getFAQSchema(faqContent) : '';
  const expansibleContent = isLandingPage ? faqContent.map( item => ( { id: item.id, title: item.question, content: item.answer} )) : {};

  const dealerParams = {
    ...props,
    search: {
      ...props.search,
      count: count
    },
    match: {
      ...props.match,
      params: {
        ...params,
        page: get(params, 'page', 1).toString(),
        pageSize: get(params, 'pageSize', 8),
        countrySubDivisionCode: state
      }
    }
  };

  if (!Array.isArray(records)) {
    return null;
  }

  const brandKeys = brands?.map(brand => brand.key);

  return (
    <>
      { isLandingPage &&
        <RichSnippet schema={faqsSchema} />
      }
      <DefaultLayout {...dealerParams} pageType="BoatDealers" id={id}>
        <div className="dealer-filters-container">
          <DealerFilters brands={brandKeys} zips={zips} cities={cities} startParams={params} history={history} states={states} />
        </div>
        { loading ?
          <div className="dealers-spinner">
            <Spinner/>
          </div> :
          <>
            <div className="dealers-container">
              <div className="content-head">
                <BreadCrumb items={breadCrumbs.breadCrumbs}/>
              </div>
              <h1 data-e2e = "dealers-header" data-test-sp="srp-header-h1">{SRPHeader}</h1>
              {resultsText && <p className="dealers-reasults-text">{resultsText}</p>}
              <DealerList dealers={records} page={props.params.page} />
              <Pagination {...props} getUrl={getPaginationUrl}/>
            </div>
            <AdProvider
              url={get(props, 'location.pathname', '')}
              isWorking={props.isWorking}
              targeting={paramsWithStateName}
              adsConfig={dealersAdsConfig}>

              <div className="dealers-locations">
                <DealerLocations locations={locations} filters={params} hasResults={records.length > 0}/>
              </div>

              <div className="dealers-makes">
                <DealerMakes topMakes={topMakes} filters={params} allMakes={brands}/>
              </div>

              { isLandingPage &&
                <div className="dealers-articles">
                  <DealerRelatedArticles />
                </div>
              }

              { isLandingPage &&
                <ExpansibleInfoTable
                  sectionId={'dealers-faqs'}
                  titleHeader={'Dealer FAQs'}
                  content={expansibleContent}
                  titleType={'h3'} />
              }

              <DealerAd
                desktopId={'div-gpt-leaderboard-1'}
                mobileId={'div-gpt-mobile-box-1'}
                display={showAdds} />

            </AdProvider>
          </>
        }
      </DefaultLayout>
    </>
  );
};

export default MainContent;
