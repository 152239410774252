import React, { useState } from 'react';
import './styles.css';
import classnames from 'classnames';
import ListingContactForm from '../../../components/ListingContactForm';
import { toBoolean } from '../../../utils/commonHelper';
import SponsoredBoatElement from './SponsoredBoatElement';

const sponsoredBoatList = ({
  sponsoredListings,
  myboats,
  url,
  tridentTeaserRate,
  onRequestInfo
}) => {
  return sponsoredListings.map((listing, index) => {

    return (
      <SponsoredBoatElement
        key={`sponsoredBoat-${listing.id}`}
        listing={listing}
        myboats={myboats}
        tridentTeaserRate={tridentTeaserRate}
        url={url}
        onRequestInfo={onRequestInfo}
        position={index + 1}
      />
    );
  });
};

const SponsoredBoatsSection = ({ boats }) => {
  return (
    <div
      data-nosnippet
      id="sponsored-boats-section"
      data-e2e="srp-sponsored-boats-section"
      className={classnames('sponsored-boats', 'ANA_1776')}
    >
      <ul>{boats}</ul>
    </div>
  );
};

const SponsoredBoats = ({
  sponsoredListings,
  myboats,
  cookies,
  criteria,
  tridentTeaserRate,
  url = '',
}) => {
  const [contactProps, setContactProps] = useState(null);

  if (!sponsoredListings?.length) {
    return null;
  }

  const onClose = () => {
    setContactProps(null);
  };
  const onRequestInfo = (contactProps) => {
    setContactProps(contactProps);
  };
  const boats = sponsoredBoatList({
    sponsoredListings,
    myboats,
    cookies,
    criteria,
    tridentTeaserRate,
    url,
    onClose,
    onRequestInfo,
  });
  const openContactForm = toBoolean(contactProps);
  return (
    <div id="sponsored-boats">
      <ListingContactForm
        open={openContactForm}
        onClose={() => onClose(contactProps)}
        {...contactProps}
      />
      <SponsoredBoatsSection boats={boats} />
    </div>
  );
};

export default SponsoredBoats;
