import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ServicesItem from '../../../components/ServicesItem';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import { setCollapseContentTracking } from '../../../store/actions/dataLayer';
import ServicesAd from '../../../components/Ads/ServicesAd';
import {
  DOCUMENTATION_SERVICE,
  FINANCE_SERVICE,
  TRIDENT_PROVIDER,
  YACHTCLOSER_PROVIDER
} from '../../../constants/services';

import './styles.css';

class Services extends PureComponent {

  render() {
    const { setCollapseContentTracking } = this.props;
    return (
      <CollapsibleContent initialState="open" classOverride="details services-collapsible">
        <CollapsibleHeader onClick={
          (e,open) => {
            if (open) {
              setCollapseContentTracking('services');
            }
          }
        }>
          Other Services
        </CollapsibleHeader>
        <Collapsible type="no-pad">
          <div className="services">
            <ServicesAd name="shipping" />
            <ServicesAd name="Insurance" />
            <ServicesItem
              link="/boat-loans?utm_content=BDPFinanceFooterBlock"
              serviceTitle="Get boat financing today"
              serviceIcon="btLogo"
              serviceType={FINANCE_SERVICE}
              serviceName={TRIDENT_PROVIDER}
            />
            <ServicesItem
              link="/services/boat-documentation/"
              serviceTitle="Boat Documentation"
              serviceIcon="documentation"
              serviceType={DOCUMENTATION_SERVICE}
              serviceName={YACHTCLOSER_PROVIDER}
            />
            <ServicesAd name="ad-rental" />
            <ServicesAd name="ad-service-1" />
            <ServicesAd name="ad-service-2" />
          </div>
        </Collapsible>
      </CollapsibleContent>
    );
  }
}

export default connect(null ,dispatch => bindActionCreators({
  setCollapseContentTracking,
}, dispatch))(Services);

