import get from 'lodash/get';
export const buildRelatedListingParamsFromListing = (paramList, listing) => {
  const params = {
    numOfListings: 3,
    sort: 'random'
  };

  params['!id'] = get(listing, 'id');

  if (paramList.includes('location') && get(listing, 'location.coordinates[0]') && get(listing, 'location.coordinates[1]')) {
    params.lat = get(listing, 'location.coordinates[1]');
    params.lon = get(listing, 'location.coordinates[0]');
  }
  if (paramList.includes('make')) {
    params.make = get(listing, 'make');
  }
  if (paramList.includes('model')) {
    params.model = get(listing, 'model');
  }
  if (paramList.includes('type')) {
    params.type = get(listing, 'type');
  }

  return params;
};
