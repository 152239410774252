import React, {PureComponent} from 'react';
import { Redirect } from 'react-router-dom';
import DatalinkContent from './DatalinkContent';
import DefaultLayout from '../../layouts/Default';
import DataLinkLayout from '../../layouts/Datalink';
import ErrorMessage from '../../components/ErrorMessage';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import get from 'lodash/get';
import { getBoatUrl } from '../../utils/urlHelpers/boat';
import { generateSearchPath } from '../../utils/urlHelpers/listings';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import * as tridentActions from '../../store/actions/tridentApi';
import { UNAVAILABILITY_REDIRECT_COOKIE } from '../../constants/boatDetails';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import {runOnce} from '../../utils/runOnceHelper';
import BoatDetailsPage from './MainContent/BoatDetailsPage';
import BrokerDealerBDP from './MainContent/BrokerDealerBDP';
import BDPOem from './MainContent/BDPOem';
import BDPSeller from './MainContent/BDPSeller';
import { useBdpPageType } from './MainContent/bdpController/bdpManager';
import { cookiesLib } from '../../utils/cookies';
import queryString from 'query-string';

/****************************************************
A refactor has been done to separate the BDP in two:
- For Brokers and Sellers we have the BDPBrokerDealer.
- For OEMs and FSBO we have the MainContent.

There are new functional components on BDPSections folder
wrapping the old ones, so we can reuse them on the new and
separate BDP sections.

We can refactor the MainContent to a functional component
also.

Finally, there's work to do to change all class components
to functional components, and use hooks.
****************************************************/

const BDPBrokerDealer = (props) => {
  return (
    <BoatDetailsPage {...props}>
      <BrokerDealerBDP {...props} />
    </BoatDetailsPage>
  );
};
const BDPoem = (props) => {
  return (
    <BoatDetailsPage {...props}>
      <BDPOem {...props} />
    </BoatDetailsPage>
  );
};
const BDPseller = (props) => {
  return (
    <BoatDetailsPage {...props}>
      <BDPSeller {...props} />
    </BoatDetailsPage>
  );
};


const BoatDetailsComponent = (props) => {
  const pageType = useBdpPageType(props);
  const noAdsParam = get(queryString.parse(props.location?.search), 'noAds', null) === 'true';
  switch (pageType) {
  case 'oem':
    return <BDPoem {...props} noAdsParam={noAdsParam} />;
  case 'fsbo':
    return <BDPseller {...props} noAdsParam={noAdsParam} />;
  default:
    return <BDPBrokerDealer {...props} noAdsParam={noAdsParam} />;
  }
};

class BoatDetails extends PureComponent {

  constructor(props) {
    super(props);
    let { cookies, statusCode } = this.props;
    if (statusCode === 301 && cookiesLib.canCreateCookies('required', cookies.cookies)) {
      cookies.set(UNAVAILABILITY_REDIRECT_COOKIE, true);
    }
  }

  renderDefaultLayout() {
    return (
      <DefaultLayout {...this.props} pageType="BoatDetails">
        {this.props.errors ?
          <ErrorMessage {...this.props} />
          :
          <BoatDetailsComponent {...this.props} />
        }
      </DefaultLayout>
    );
  }

  renderDatalinkLayout() {
    return (
      <DataLinkLayout {...this.props} pageType="BoatDetails">
        {this.props.errors ?
          <ErrorMessage {...this.props} />
          :
          <DatalinkContent {...this.props} />
        }
      </DataLinkLayout>
    );
  }

  render() {
    let { listing, location, statusCode } = this.props;
    const isNotCanonicalUrl = listing && !(getBoatUrl(listing).includes(get(location, 'pathname')));

    if (statusCode === 301) {
      let searchPathData = { make: normalizeString(listing.make) };
      if (listing.model) {
        searchPathData.model = normalizeString(listing.model);
      }
      const pathname = generateSearchPath(searchPathData);
      return <Redirect to={{pathname}} />;
    }
    if (utils.isServer() && isNotCanonicalUrl && this.props.success) {
      let canonicalRedirect = getBoatUrl(listing) + get(location, 'search');
      return <Redirect to={canonicalRedirect} {...this.props} />;
    }
    const pathNameParams = get(location, 'search');
    if (pathNameParams.match(/(\?|&)datalink/)) {
      return (this.renderDatalinkLayout());
    }
    return (this.renderDefaultLayout());
  }
}

const processTridentLoanSpecs = (state) => {
  const teaserRate = get(state.app, 'trident.rates[0].teaserRate');
  const listing = get(state.app, 'data', {});
  const loanAmount = parseFloat(listing.price?.type?.amount?.USD || 0);
  const ficoScores = tridentActions.getFicoScores(get(state.app, 'trident.rates[0].rates', []));

  return {
    tridentTeaserRate: parseFloat(teaserRate?.replace(/[%, ]/g, '')),
    tridentLoanAmount: loanAmount,
    tridentTermInMonths: loanAmount >= 50000 ? 240 : 180,
    ficoScores
  };
};

const mapStateToProps = state => {
  const {
    tridentTeaserRate,
    tridentLoanAmount,
    tridentTermInMonths,
    ficoScores
  } = processTridentLoanSpecs(state);

  return {
    params: get(state.app, 'params', {}),
    listing: get(state.app, 'data', {}),
    sponsored: get(state.app, 'data.sponsored', {}),
    interestingInformation: get(state.app, 'data.interestingInformation', {}),
    isWorking: state.app.isWorking,
    componentWorking: state.app.componentWorking,
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode,
    user: state.app.user,
    myboats: state.app.myboats,
    monthlyPrice: state.app.monthlyPrice,
    carouselClickCount: state.app.carouselClickCount,
    carouselClickCountModal: state.app.carouselClickCountModal,
    navLinks: state.app.navLinks,
    userCountryCode: state.app.userCountryCode,
    userCountryCodeError: state.app.userCountryCodeError,
    oemDetails: get(state.app, 'data.oemDetails', {}),
    videos: get(state.app, 'data.videos', []),
    boatLoansSection: get(state.app, 'trident.boatLoansSection', {error: true}),
    tridentTeaserRate,
    tridentLoanAmount,
    tridentTermInMonths,
    ficoScores,
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false),
    userRef: get(state.app, 'userRef', {}),
    userEmail: get(state.app, 'userEmail')
  };
};

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

export default withCookies(connect(
  mapStateToProps,
  null
)(frontloadConnect(async ({ dispatch, location, cookies, debug = process.env.REACT_APP_LOCAL_DEBUG }) => {
  if (utils.isServer() || actions.shouldGetData(location)) {
    return await Promise.allSettled([
      dispatch(actions.getBoatData(location.pathname, cookies.cookies)),
      dispatch(tridentActions.getRatesFromTridentAPI()),
      dispatch(tridentActions.getBoatLoansFromEditorialAPI())
    ]);
  }
  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here, and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  /* istanbul ignore next */
  if (justFirsTime() && debug) {
    return await Promise.allSettled([
      dispatch(actions.getBoatData(location.pathname, cookies.cookies)),
      dispatch(tridentActions.getRatesFromTridentAPI()),
      dispatch(tridentActions.getBoatLoansFromEditorialAPI())
    ]);
  }
}, {
  onUpdate: true,
  onMount: true
})(BoatDetails)));
