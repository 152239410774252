import React from 'react';
import { IntroductoryContent } from './components/IntroductoryContent';
import { DesktopTabView } from './components/DesktopTabView';
import { MobileAccordion } from './components/MobileAccordionView';
import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';
import { TRACKING_BOAT_LOANS_FINANCING_APPLY_ONLINE_ACTION_LABEL } from '../../../../constants/BoatLoansV2';

export const BoatFinancingSection = () => {
  return (
    <>
      <div className="financing-parent-container">
        <div className="financing-content-container">
          <div className="financing-content">
            <IntroductoryContent />
            <DesktopTabView />
            <MobileAccordion />
          </div>
        </div>
        <div className="financing-footer">
          <div className="apply-link-button-container">
            <ApplyNowButton
              text="Get pre-qualified"
              type="button"
              utmCampaign="btboatloanspage"
              utmContent="btboatloanspage"
              data-reporting-click-internal-link-type="click"
              data-reporting-click-internal-link-id="financing - apply online in 5 minutes"
              trackingEvent={{
                'action_label': TRACKING_BOAT_LOANS_FINANCING_APPLY_ONLINE_ACTION_LABEL
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};


