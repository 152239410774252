import { formatPrice } from '@dmm/lib-common/lib/formatting';
import { sanitizeHtml } from '@dmm/lib-common/lib/sanitizeHtml';
import { Bestimate } from '@dmm/lib-react-bestimate';
import { BoatDetails, InfoBoxButton, SimpleInfoBox } from '@dmm/lib-react-ui-components';
import '@dmm/lib-react-videos/dist/@dmm/lib-react-videos.min.css';
import { Ad } from '@dmm/react-common-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { Suspense, lazy, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoatLoans from '../../../components/BoatLoans';
import { BoatLoansAndServices } from '../../../components/BoatLoansAndServices';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import DataTable from '../../../components/DataTable';
import DescriptionBox from '../../../components/DescriptionBox';
import LocationMap from '../../../components/LocationMap';
import PaymentCalculator from '../../../components/PaymentCalculator';
import { PrivateSellerInfo } from '../../../components/PrivateSellerInfo';
import { BESTIMATE_PORTAL_LEAD_SOURCE, ENABLE_BDP_BESTIMATE } from '../../../constants/boatDetails';
import VideoReviews from '../../../containers/BoatDetails/DetailsSection/VideoReviews';
import { withABTest } from '../../../server/abTest/withABTest';
import { addLead, setCollapseContentTracking, setGenericEvent, trackContactFormOpen } from '../../../store/actions/dataLayer';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import { detailsMapper } from '../../../utils/infoBoxButtonHelper';
import {
  formatMoreDetailsDescriptions,
  getBoatDetails,
  getFeaturesData,
  getLocation,
  getMeasurementsData,
  getPriceData,
  getPropulsionData,
  getTitle,
} from '../../../utils/listingHelper';
import { isFinanceable } from '../../../utils/trident';
import { formatReportFraudEmailWithParams } from '../../../utils/urlHelpers/email';
import ContactGroupInLine from '../ContactGroupInLine';
import DealerOtherBoats from '../DealerOtherBoats';
import Services from '../Services';
import './styles.css';

const DetailsContact = lazy(() => import('../../BoatDetails/DetailsContact'));


const sanitizeHtmlConfig = {
  allowedTags: ['p', 'br'],
  allowedAttributes: {
    'p': ['style'],
  },
  allowedStyles: {
    'p': {
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/]
    }
  }
};

const DetailsSection = (props) => {
  const [contactForm, setContactForm] = useState({
    type: '',
    form: undefined
  });

  const renderLoader = () => <div className="preloader"></div>;

  const handleAskSellerButtonClick = () => {
    const { setGenericEvent } = props;
    if (!contactForm.form || contactForm.type !== 'contact') {
      addContactForm('contact');
      setGenericEvent('listing interactions', 'button click', 'bestimate ask seller');
    } else {
      handleAskSellerButtonClose();
    }
  };

  const handleAskSellerButtonClose = () => {
    setContactForm({
      type: '',
      form: undefined
    }
    );
  };

  const addContactForm = (type) => {
    let { listing, stateDetailsContact } = props;

    setContactForm({
      type: type,
      form:
      <Suspense fallback={renderLoader()}>
        <DetailsContact
          showCloseButton={true}
          closeButtonFunc={handleAskSellerButtonClose}
          leadSourceOverride={BESTIMATE_PORTAL_LEAD_SOURCE}
          initialState="open"
          toggle={true}
          externalOpen={stateDetailsContact === 'open'}
          disableSticky={true}
          isLoading={false}
          listing={listing}
          disableSellerInfo={true}
          prePopulatedText="Your Message"
          id="bestimate-modal"
          placeholder={{
            name: 'Your Name',
            email: 'Your Email',
            phone: 'Your Phone (Opt.)',
            comments: 'Questions/Comments',
            firstName: 'First Name'
          }}
        />
      </Suspense>
    });
  };

  const shouldRenderBestimate = () => {
    let { listing } = props;

    return !get(listing, 'price.hidden', false) && !get(listing, 'bestimate.hide', true) && get(listing, 'bestimate.displayInApp', true) &&
      get(listing, 'price.type.amount.USD') === get(listing, 'bestimate.listingPrice');
  };

  const buildBestimateObject = (bestimateInfo) => {
    let { listing } = props;

    bestimateInfo.mode = 'buyer';
    bestimateInfo.price = {
      text: 'Listing price',
      textSeparator: ':',
      listingPrice: get(listing, 'bestimate.listingPrice'),
      bestimate: get(listing, 'bestimate.bestimate'),
      lower: get(listing, 'bestimate.lower'),
      upper: get(listing, 'bestimate.upper'),
      formatted: {
        listingPrice: formatPrice(Math.abs(get(listing, 'bestimate.listingPrice')), 'USD', 'en-US'),
        bestimate: formatPrice(Math.abs(get(listing, 'bestimate.bestimate')), 'USD', 'en-US'),
        lower: formatPrice(Math.abs(get(listing, 'bestimate.lower')), 'USD', 'en-US'),
        upper: formatPrice(Math.abs(get(listing, 'bestimate.upper')), 'USD', 'en-US')
      }
    };
    bestimateInfo.group = {
      group: get(listing, 'bestimate.group'),
      textEnum: ['below average', 'average', 'above average'],
      guidanceText: 'The price is {textEnumGroup} based on our Bestimate.'
    };
    bestimateInfo.askSeller = {
      text: 'Interested in learning more about the price of this boat?',
      buttonText: 'Ask Seller',
      onClickButton: handleAskSellerButtonClick
    };
    bestimateInfo.tooltip = {
      title: 'What is Bestimate',
      text: 'The Bestimate is the best estimate of this boats market value. Our Bestimate price labels are intended to act as a guide to help you make an informed ' +
        'decision and have smarter conversations with the seller.',
      groupsTitle: 'How we measure a boat',
      groups: [
        {
          title: 'Bestimate {{groupTextEnum[0]}} Range',
          text: 'The seller has listed this boat at below the average market value. Factors such as boat condition, engine hours, electronics and other features may ' +
            'contribute to the price of this boat. Details and listing price for this boat have been provided by the seller and may not include additional fees.'
        },
        {
          title: 'Bestimate {{groupTextEnum[1]}} Range',
          text: 'The seller has listed this boat at average market value. This estimate is what you can reasonably expect to pay for this make, model, and features ' +
            'included. Details and listing price for this boat have been provided by the seller and may not include additional fees.'
        },
        {
          title: 'Bestimate {{groupTextEnum[2]}} Range',
          text: 'The seller has listed this boat at above average market value. Factors such as excellent condition, low engine hours, upgraded electronics and ' +
            'other features may contribute to the price of this boat. Details and listing price for this boat have been provided by the seller and may not include additional fees.'
        }
      ]
    };

    return true;
  };


  const { listing, isLoading, setCollapseContentTracking, myboats,
    cookies, url, openPaymentSection, openLocationSection, openBoatLoansSection, openPaymentCounter,
    openLocationCounter, openBoatLoansCounter, adParams, breakpoint, isDatalink, onClickRequestInformation,
    boatLoansSection: editorialContent, isLeadSubmitted, abTestConfiguration } = props;
  let paymentCalculatorOpenState = openPaymentSection && openPaymentSection !== 'payment-calculator' ? 'close' : 'open';
  let locationMapOpenState = openLocationSection !== 'location-map' ? 'close' : 'open';
  let boatLoansOpenState = openBoatLoansSection !== 'boat-loans' ? 'close' : 'open';

  const title = getTitle(listing);
  const dealerListings = listing.dealerListings;
  const propulsionData = getPropulsionData(listing);
  const measurementsData = getMeasurementsData(listing);
  const featuresData = getFeaturesData(listing);
  const moreDetails = formatMoreDetailsDescriptions(listing.descriptions);
  const fsbo = get(listing, 'contact.name');
  const website = get(listing, 'contact.website');
  const coordinatesBoat = get(listing, 'location.coordinates', {});
  const address = getLocation(listing);
  const priceData = getPriceData(listing);
  let bestimateInfo = {};
  const renderBestimate = ENABLE_BDP_BESTIMATE && get(listing, 'bestimate')
    && shouldRenderBestimate() && buildBestimateObject(bestimateInfo);
  const oemName = get(listing, 'oemDetails.name');
  const videos = get(listing, 'videos', []);
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const { oemDetails } = listing;
  const showDealers = oemDetails && oemDetails.oemDealerLocator;
  const securityUrl = process.env.REACT_APP_SECURITY_URL;

  const isPrivateSeller = fsbo === 'Private Seller';
  const price = get(listing, 'price.type.amount.USD');
  const financeable = isFinanceable(price, listing.year);
  const isUserLoginExperiment = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);
  const showDetails =
    !financeable ||
    (listing.condition === 'new' && listing.featureType?.enhanced === true && listing.featureType?.sponsored === true) ||
    (listing.condition === 'used' && listing.featureType?.enhanced === false && listing.featureType?.sponsored === false && hideTridentFeatures) ||
    breakpoint === 'desktop';

  return (
    <div className="details">
      {(showDetails) &&
        <BoatDetails details={getBoatDetails(listing)} />
      }
      { listing.price && !listing.price.hidden && !isEmpty(listing.price.detail) ?
        <CollapsibleContent initialState="open" classOverride="details">
          <CollapsibleHeader onClick={(e,open) => {if (open) {setCollapseContentTracking('pricing-details');}}} >
            Pricing Details
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="pricing-details" data={priceData} isLoading={isLoading} priceQualifier={listing.priceQualifier}/>
          </Collapsible>
        </CollapsibleContent>
        : null
      }

      {isPrivateSeller &&
        <CollapsibleContent initialState={props.shouldShowFinanceSummary ? 'closed' : 'open'} classOverride="details">
          <CollapsibleHeader>Boat Loans & Services</CollapsibleHeader>
          <Collapsible type="no-pad">
            <BoatLoansAndServices
              financeable={financeable}
              showModalServices={props?.showModalPreQualified}
              listing={listing}
            />
          </Collapsible>
        </CollapsibleContent>
      }

      <CollapsibleContent initialState="open" classOverride="details">
        <CollapsibleHeader onClick={(e,open) => {if (open) {setCollapseContentTracking('boat-description');}}} >
          Description
        </CollapsibleHeader>
        <Collapsible type="no-pad">
          <span data-test-sp="bdp-boat-title" className="boat-title">{title}</span>
          <DescriptionBox dataName="description" showMore={true} data={get(listing, 'description', '')} isLoading={isLoading} tracking={setCollapseContentTracking}/>
        </Collapsible>
        { !showDealers && (
          <div className="cta-wrapper">
            <InfoBoxButton
              title={ detailsMapper.description.title }
              content={ detailsMapper.description.content }
              classNames={{
                icon: true
              }}
              icon="description"
              onClick={ (e) => { onClickRequestInformation(e, { eventName: 'infobox', type: 'description' }, detailsMapper.description.eventLabel); } }
            />
          </div>
        )}
      </CollapsibleContent>

      {oemName && get(listing, 'oemDetails.intro') &&
        <CollapsibleContent initialState="open" classOverride="details">
          <CollapsibleHeader>About {oemName}</CollapsibleHeader>
          <Collapsible type="no-pad">
            <DescriptionBox dataName="intro" showMore={true} data={sanitizeHtml(listing.oemDetails.intro, sanitizeHtmlConfig)} isLoading={isLoading} tracking={setCollapseContentTracking} />
          </Collapsible>
        </CollapsibleContent>}

      { renderBestimate && contactForm.form}
      { renderBestimate ?
        <CollapsibleContent initialState="open" classOverride="details" id="bestimate-data">
          <CollapsibleHeader onClick={(e,open) => {if (open) {setCollapseContentTracking('boat-description');}}} >
            Bestimate
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <Bestimate {...bestimateInfo}></Bestimate>
          </Collapsible>
        </CollapsibleContent>
        : null
      }


      { measurementsData.length > 0 ?
        <CollapsibleContent initialState="closed" classOverride="details">
          <CollapsibleHeader onClick={(e,open) => {if (open) {setCollapseContentTracking('measurements');}}} >
            Measurements
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="measurements" data={measurementsData} isLoading={isLoading}/>
          </Collapsible>
          { !showDealers && (
            <div className="cta-wrapper" data-testid="info-box-button">
              <InfoBoxButton
                title={ detailsMapper.measurements.title }
                content={ detailsMapper.measurements.content }
                classNames={{
                  icon: true
                }}
                icon="measurements"
                onClick={ (event) => { onClickRequestInformation(event, { eventName: 'infobox', type: 'measurements' }, detailsMapper.measurements.eventLabel); } }
              />
            </div>
          )}
        </CollapsibleContent>
        : null
      }

      { propulsionData.length > 0
        ? <CollapsibleContent initialState="closed" classOverride="details">
          <CollapsibleHeader onClick={(e,open) => {if (open) { setCollapseContentTracking('propulsion'); }}} >
            Propulsion
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="propulsion" data={propulsionData} isLoading={isLoading}/>
          </Collapsible>
          {!showDealers && (
            <div className="cta-wrapper">
              <InfoBoxButton
                title={ detailsMapper.propulsion.title }
                content={ detailsMapper.propulsion.content }
                classNames={{
                  icon: true
                }}
                icon="propulsion"
                onClick={ (event) => { onClickRequestInformation(event, { eventName: 'infobox', type: 'propulsion' }, detailsMapper.propulsion.eventLabel); } }
              />
            </div>
          )}
        </CollapsibleContent>
        : null
      }

      { featuresData.length > 0
        ? <CollapsibleContent initialState="closed" classOverride="details">
          <CollapsibleHeader onClick={(e,open) => {if (open) { setCollapseContentTracking('features'); }}} >
            Features
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DataTable dataName="features" data={featuresData} isLoading={isLoading}/>
          </Collapsible>
        </CollapsibleContent>
        : null
      }

      { moreDetails && moreDetails.length > 0
        ? <CollapsibleContent initialState="closed" classOverride="details">
          <CollapsibleHeader onClick={(e,open) => {if (open) { setCollapseContentTracking('more-details'); }}} >
            More Details
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DescriptionBox dataName="more-details" showMore={false} multiItem={true} data={moreDetails} isLoading={isLoading}/>
          </Collapsible>
        </CollapsibleContent>
        : null
      }

      { videos && videos.length > 0
        ? <CollapsibleContent initialState="open" classOverride="details">
          <CollapsibleHeader>
            Expert Video Reviews
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <VideoReviews
              videos={videos}
              device={breakpoint}
            />
          </Collapsible>
        </CollapsibleContent>
        : null
      }

      { breakpoint !== 'desktop' && <ContactGroupInLine onOpenContact={onClickRequestInformation} listing={listing} /> }

      <PaymentCalculator
        open={paymentCalculatorOpenState}
        initialState={paymentCalculatorOpenState}
        counter={openPaymentCounter}
        listing={listing}
        isLoading={isLoading}
        tridentLoanAmount={props?.tridentLoanAmount}
        tridentTeaserRate={props?.tridentTeaserRate}
        tridentTermInMonths={props?.tridentTermInMonths}
        trackGetPreQualifiedClicked={props?.trackGetPreQualifiedClicked}
        utmCampaign="BDPcalculatorapply"
        isLeadSubmitted={isLeadSubmitted}
        isPrivateSeller={isPrivateSeller}
      />
      {!financeable && !hideTridentFeatures && !isPrivateSeller &&
        <BoatLoans
          open={boatLoansOpenState}
          counter={openBoatLoansCounter}
          listing={listing}
          isLoading={isLoading}
          editorialContent={editorialContent}
          breakpoint={breakpoint}
        />
      }
      { breakpoint !== 'desktop' && !isDatalink ?
        <div className="tablet-ads-wrapper">
          <Ad {...adParams.mobileBox1Params} />
          { breakpoint === 'tablet' &&  <Ad {...adParams.mobileBox2Params} /> }
        </div>
        : null
      }

      { !isDatalink && fsbo !== 'Private Seller' && dealerListings && dealerListings.length > 0 ?
        <CollapsibleContent initialState="open" classOverride="details more-from-dealer-collapsible">
          <CollapsibleHeader onClick={(e, open) => { if (open) { setCollapseContentTracking('dealerlistings'); } }} >
            {oemName ? `More from ${oemName}` : 'More From This Dealer'}
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            <DealerOtherBoats
              dealerListings={dealerListings}
              isLoading={props.isWorking}
              listingId={get(listing, 'id')}
              myboats={myboats}
              cookies={cookies}
              url={url}
              website={website}
              hideMoreLink={!!oemName}
              owner={get(listing, 'owner')}
              showMoreBoats={!oemDetails}
              mobile={breakpoint !== 'desktop'}
              listing={listing}
            />
          </Collapsible>
        </CollapsibleContent>
        : null
      }

      {isUserLoginExperiment && <PrivateSellerInfo />}

      {!showDealers && (
        <div className="infopanel-wrapper">
          <SimpleInfoBox
            title={ detailsMapper.services.title }
            classNames={{ shadow: breakpoint === 'mobile' }}
            link={{
              text: detailsMapper.services.content,
              onClick: (event) => { onClickRequestInformation(event, { eventName: 'infobox', type: 'services' }, detailsMapper.services.eventLabel); }
            }}>
            Get answers, schedule a visit to see the boat, or find a good time for a sea trial.
            <br />
            <br/>
            Take the next step and contact the seller.
          </SimpleInfoBox>
        </div>
      )}

      { !isDatalink && <Services listing={listing} /> }
      {!isEmpty(coordinatesBoat) && address && !isPrivateSeller
        ? <LocationMap
          open={locationMapOpenState}
          counter={openLocationCounter}
          listing={listing}
          isLoading={isLoading}
          setCollapseContentTracking={props.setCollapseContentTracking}
          addLead={props.addLead}
          onClickRequestInformation={onClickRequestInformation}
        />
        : null
      }

      {(!hideTridentFeatures || isPrivateSeller)
        ? (
          <div className="trident-funding-disclaimer-wrapper">
            <span className="disclaimer">
              Trident Funding LLC markets and sells extended warranties on behalf of 3rd party providers and your extended warranty will be between you and the provider.  Coverage, deductibles, and claims payment vary depending on vehicle age, mileage, pre-existing conditions and program selected. Repairs can be made by any licensed repair facility. Program(s) available in most states. Not available in California or Florida. You consent to be contacted by email, phone, and/or text by submitting your quote request. Texas and Wisconsin Residents: Contracts administered by Dealer Admin Services, 5810 W 78th Street, Suite 300, Bloomington, MN 55439, <span className="underline">877-746-4917</span>.  Vehicle names, logos, brands, & other trademarks featured or referred to within Trident Funding are property of their respective trademark holders. Extended warranties will not be sold where prohibited by law.
            </span>
          </div>
        )
        : null
      }

      {isPrivateSeller &&
        <div className="security-info">
          <div className="header">Something not right?</div>
          <p>Is the boat information inaccurate or suspicious? <a href={`mailto:${formatReportFraudEmailWithParams('fraudalert@boattrader.com', listing)}`}>Report It</a><br/>Knowledge and awareness are the best defense to prevent fraud. <a href={securityUrl} target="_blank" rel="noopener">Please read our fraud awareness tips</a> to protect yourself and others!</p>
        </div>
      }
    </div>
  );
};

export default withABTest(connect(null, dispatch => bindActionCreators({
  setCollapseContentTracking,
  trackContactFormOpen,
  setGenericEvent,
  addLead
}, dispatch))(DetailsSection));
