import { AdProvider } from '@dmm/react-common-components';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import ImmersiveTourModal from '../../../components/ImmersiveTourModal';
import NextPreviousDataProvider from '../../../components/NextPreviousDataProvider';
import ScrollManager from '../../../components/ScrollManager';
import Sticky from '../../../components/Sticky';
import { withABTest } from '../../../server/abTest/withABTest';
import { detailsMapper } from '../../../utils/infoBoxButtonHelper';
import { getPrePopulatedText } from '../../../utils/listingHelper';
import * as storage from '../../../utils/storage';
import { isSegmentCandidateForFinance } from '../../../utils/trident';
import BDPAd from '../BDPSections/BDPAd';
import BDPAlert from '../BDPSections/BDPAlert';
import BDPBoatSummary from '../BDPSections/BDPBoatSummary';
import FinanceSummary from '../FinanceSummary';
import BDPCarouselModal from '../BDPSections/BDPCarouselModal';
import { BDPDealerLocator, BDPDealerLocatorForm } from '../BDPSections/BDPDealerLocator';
import BDPDesktopAds from '../BDPSections/BDPDesktopAds';
import BDPDetailsSection from '../BDPSections/BDPDetailsSection';
import BDPEmailLeadForm from '../BDPSections/BDPEmailLeadForm';
import BDPHelmet from '../BDPSections/BDPHelmet';
import BDPScrollAnchor from '../BDPSections/BDPScrollAnchor';
import DetailsContact from '../DetailsContact';
import NextPrevious from '../NextPrevious';
import { parseDetailsSectionState } from './bdpController/bdpHelpers';
import { useBdp } from './bdpController/bdpManager';
import { adsConfig, getTargeting } from './resources/adsConfig';
import './styles.css';
const AdditionalLeadsModal = loadable(() => import('../../../components/AdditionalLeadsModal'));

const BrokerDealerBDP = (props) => {
  const {state, bdp} = useBdp(props);
  const {
    breakpoint,
    showModalDealerLocator,
    showModalImmersiveTour,
    showModalCarousel,
    carouselIndex,
    openContactForm,
    setListingViewSent
  } = state;

  const showModalPostLead = useSelector((state) => get(state, 'app.additionalLeads.open', false));

  const {bdpContext, bdpHandlers, carouselButtons} = bdp;
  const {
    url, mediaImages, breadCrumbItems, isFSBO,
    listingTitle, virtualTourUrl, locationPath
  } = bdpContext;

  const {
    isWorking,
    listing,
    cookies,
    myboats = [],
    carouselClickCountModal,
    carouselClickCount,
    tridentTermInMonths,
    tridentLoanAmount,
    tridentTeaserRate,
    abTestConfiguration,
    noAdsParam
  } = props;

  const {
    summaryHandlers, hideModals, handleOpenContactForm, openCarousel,
    closeImmersiveTourModal, openImmersiveTourModal, openModalPostLead, previousNextHandler,
    openModalDealerLocator, handleCloseContactForm, displayModalPreQualified,
    handleTrackOpenContactForm, setProductData, setListingViewData, setGenericEvent
  } = bdpHandlers;

  const {oemDetails} = listing;
  const showMoreBoats = !oemDetails;
  const showDealers = oemDetails && oemDetails.oemDealerLocator;
  const showBoatSummary = !!(!showModalCarousel && !showDealers);
  // Variant FINANCE 2
  const shouldShowFinanceSummary = isSegmentCandidateForFinance(listing, abTestConfiguration);

  let targeting = getTargeting(props.listing, Math.floor(carouselClickCount / 3).toString());

  useEffect(() => {
    setProductData(listing);
    targeting = getTargeting(props.listing, Math.floor(carouselClickCount / 3).toString()); // eslint-disable-line react-hooks/exhaustive-deps
    setListingViewData(listing, setListingViewSent);
  }, [setProductData(listing), setListingViewData(listing, setListingViewSent)]);

  const reloadAdOverride = Math.floor(carouselClickCount / 3) || 0;
  const reloadAdModal = Math.floor(carouselClickCountModal / 5) || 0;
  const reloadAdMobile = Math.floor(carouselClickCount / 3) || 0;
  const reloadAdModalMobile = Math.floor(carouselClickCountModal / 6) || 0;

  const sideBarAd1Params = {
    adId: 'div-gpt-box-1',
    classes: 'right-rail-ad ad-300 box-holder',
    reloadAd: reloadAdOverride
  };
  const sideBarAd2Params = {
    adId: 'div-gpt-box-2',
    classes: 'right-rail-ad ad-300 box-holder',
  };
  const sideBarAd3Params = {
    adId: 'div-gpt-box-3',
    classes: 'right-rail-ad ad-300 box-holder',
  };
  const bdpDesktopAds = {sideBarAd1Params, sideBarAd2Params, sideBarAd3Params};
  const leaderboardTopParams = {
    adId: 'div-gpt-leaderboard-top',
    classes: 'ad-leaderboard-top',
    reloadAd: reloadAdOverride
  };
  const leaderboardBottomParams = {
    adId: 'div-gpt-leaderboard-bottom',
    classes: 'ad w728',
  };
  const mobileBox1Params = {
    adId: 'div-gpt-mobile-box-1',
    classes: 'ad',
  };
  const mobileBox2Params = {
    adId: 'div-gpt-mobile-box-2',
    classes: 'ad',
  };
  const mobileLeaderboardParams = {
    adId: 'div-gpt-mobile-leaderboard-1',
    classes: 'mobile-ad-leaderboard-top',
    reloadAd: reloadAdMobile
  };
  const mobileCarouselLeaderboardParams = {
    adId: 'div-gpt-mobile-carousel-leaderboard-1',
    classes: 'ad',
    reloadAd: reloadAdModalMobile
  };
  const leaderboardModalParams = {
    adId: 'div-gpt-modal-leaderboard-top',
    classes: 'ad w728',
    reloadAd: reloadAdModal
  };
  const carouselBox1Params = {
    adId: 'div-gpt-carousel-box-1',
    classes: 'ad left-box',
    reloadAd: reloadAdModal
  };
  const carouselAddParams = {leaderboardModalParams, mobileCarouselLeaderboardParams, carouselBox1Params};

  return (
    <>
      <BDPDealerLocator
        device={breakpoint}
        hideModal={hideModals}
        isOpen={showModalDealerLocator}
        origin="BDP"
        isWorking={isWorking}
      />
      <ImmersiveTourModal isOpen={showModalImmersiveTour}
        closeModal={closeImmersiveTourModal}
        title={listingTitle}
        url={virtualTourUrl}
      />
      <BDPHelmet listing={listing} breadCrumbItems={breadCrumbItems} mediaImages={mediaImages} />
      <AdProvider url={locationPath} isWorking={isWorking} targeting={targeting} adsConfig={adsConfig} noAdsParam={noAdsParam}>
        <div className="top-ad">
          <BDPAd
            show={true}
            addParams={breakpoint === 'desktop' ? leaderboardTopParams : mobileLeaderboardParams}/>
        </div>
        <ScrollManager />
        <NextPreviousDataProvider {...props} storage={storage} />
        <div className="broker-dealer-next-previous">
          <NextPrevious isLoading={isWorking} listing={listing} myboats={myboats} cookies={cookies}
            breadCrumbs={breadCrumbItems} url={url} navLinks={props.navLinks}
            showInfo={state.scrolledPastBoatSummary} breakpoint={breakpoint}
            tracking={{ setGenericEvent: setGenericEvent }}
            showMoreBoats={showMoreBoats} />
          <div className="boat-details boat-details-gradient">
            <BDPScrollAnchor />
            <div className="top broker-bdp">
              <div className="broker-bdp-left">
                <BDPCarouselModal
                  breakpoint={breakpoint}
                  isLoading={isWorking}
                  showMoreBoats={showMoreBoats}
                  showThumbnails={true}
                  show={showModalCarousel}
                  carouselIndex={carouselIndex}
                  hideModal={hideModals}
                  carouselAddParams={carouselAddParams}
                  carouselButtons={carouselButtons}
                  handleOpenContactForm={handleOpenContactForm}
                  openImmersiveTourModal={openImmersiveTourModal}
                  handleCarouselItemClick={(e, index) => openCarousel(index)}
                  previousNextHandler={previousNextHandler}
                />
                {breakpoint !== 'desktop' &&
                  <>
                    <BreadCrumb items={breadCrumbItems.slice(0, -1)} dataTestId="breadcrumb-component-mobile"/>
                    {!shouldShowFinanceSummary &&
                      <BDPBoatSummary
                        show={showBoatSummary}
                        isWorking={isWorking}
                        breakpoint={breakpoint}
                        summaryHandlers={summaryHandlers}
                        openModalDealerLocator={openModalDealerLocator}
                        openModal={displayModalPreQualified}
                      />
                    }
                    {shouldShowFinanceSummary &&
                      <FinanceSummary
                        listing={listing}
                        tridentTermInMonths={tridentTermInMonths}
                        tridentLoanAmount={tridentLoanAmount}
                        tridentTeaserRate={tridentTeaserRate}
                        openModalPostLead={openModalPostLead}
                        isMobile={breakpoint !== 'desktop'}
                        breakpoint={breakpoint}
                      />
                    }
                  </>
                }
                <div className="content">
                  <Sticky position="top" offset={51} onChange={summaryHandlers.handleBoatSummaryScroll}/>
                  <DetailsContact
                    isLoading={isWorking}
                    listing={listing}
                    externalOpen={openContactForm}
                    prePopulatedText={getPrePopulatedText(listing, state?.UIEvent?.type)}
                    formTitle={get(detailsMapper, `${state?.UIEvent?.type}.title`)}
                    isInfoButton={get(state, 'UIEvent.eventName') === 'infobox'}
                    closeButtonFunc={handleCloseContactForm}
                    showModalDealerLocator={openModalDealerLocator}
                    useRecaptcha={isFSBO}
                    showAdditionalLeads={true}
                    inModal={true}
                    disableSticky={breakpoint === 'desktop'}
                    hideButtons={shouldShowFinanceSummary}
                  />
                  <BDPDetailsSection
                    isWorking={isWorking}
                    myboats={myboats}
                    cookies={cookies}
                    url={url}
                    breakpoint={breakpoint}
                    listing={listing}
                    detailsState={parseDetailsSectionState(state)}
                    adParams={{mobileBox1Params, mobileBox2Params}}
                    displayModalPreQualified={displayModalPreQualified}
                    onClickRequestInformation={showDealers ? openModalDealerLocator : handleTrackOpenContactForm}
                    shouldShowFinanceSummary={shouldShowFinanceSummary}
                  />
                  <BDPAd
                    show={breakpoint === 'desktop'}
                    addParams={leaderboardBottomParams} />
                  <BDPAd
                    show={breakpoint === 'mobile'}
                    addParams={mobileBox2Params} />
                </div>
              </div>
              <div className="top-right broker-bdp-right">
                {breakpoint === 'desktop' && !shouldShowFinanceSummary &&
                  <BDPBoatSummary
                    show={showBoatSummary}
                    isWorking={isWorking}
                    breakpoint={breakpoint}
                    summaryHandlers={summaryHandlers}
                    openModalDealerLocator={openModalDealerLocator}
                    openModal={displayModalPreQualified}
                  />
                }
                <BDPEmailLeadForm
                  breakpoint={breakpoint}
                  listing={listing}
                  hideForm={shouldShowFinanceSummary}
                  onSuccess={openModalPostLead}
                />
                {breakpoint === 'desktop' && shouldShowFinanceSummary &&
                  <FinanceSummary
                    listing={listing}
                    tridentTermInMonths={tridentTermInMonths}
                    tridentLoanAmount={tridentLoanAmount}
                    tridentTeaserRate={tridentTeaserRate}
                    openModalPostLead={openModalPostLead}
                    isMobile={breakpoint !== 'desktop'}
                    breakpoint={breakpoint}
                  />
                }

                <BDPDesktopAds
                  addParams={bdpDesktopAds}
                  show={breakpoint === 'desktop'} />
              </div>
              <BDPDealerLocatorForm openModalDealerLocator={openModalDealerLocator}/>
            </div>
          </div>
        </div>
        {showModalPostLead && <AdditionalLeadsModal/>}
        <BDPAlert />
      </AdProvider>
    </>
  );
};

export default withABTest(BrokerDealerBDP);

