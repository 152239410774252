import {
  formatPrice,
  formatPriceWithDecimal,
  formatWithDecimal,
} from '@dmm/lib-common/lib/formatting';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Action } from '@dmm/lib-react-ui-components';
import classNames from 'classnames';
import { TRACKING_BOAT_LOANS_CALCULATOR_DETAILED_PAGE_NAME, TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SECTION, TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SUB_SECTION, TRACKING_BOAT_LOANS_PORTAL } from '../../constants/BoatLoansV2';
import { calculateMonthlyPrice, showCalculator } from '../../utils/trident';
import './Calculator.styles.css';

export const PrivateSellerCalculator = ({
  goToApplyNow,
  setGenericEvent,
  tridentLoanAmount,
  tridentTeaserRate,
  tridentTermInMonths,
  listing,
}) => {
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const shouldShowRightPanel = showCalculator(listing, hideTridentFeatures);
  const [calculator, setCalculator] = useState({
    autoCalculate: false,
    showErrorOnCalculate: {
      loanAmountOrMonthlyPayment: false,
      loanTermYears: false,
      loanTermMonths: false,
    },
    formFields: {
      purchasePrice: '$0.00',
      downPayment: '$0.00',
      loanTermYears: '',
      loanTermMonths: '',
      loanAmount: '',
      interestRate: '',
    },
    result: {
      monthlyPayment: '$0.00',
    },
  });

  useEffect(() => {
    let priceAmount = '$0.00';
    let termYears;
    let formattedRate;
    let termMonths;
    let autoCalc = false;

    if (tridentLoanAmount) {
      priceAmount = formatPriceWithDecimal(tridentLoanAmount, 'USD', 'en-US', 2);
      termYears = String(tridentLoanAmount >= 50000 ? 20 : 15);
      autoCalc = true;
    }

    if (tridentTeaserRate) {
      formattedRate = formatWithDecimal(
        tridentTeaserRate,
        'en-US',
        2,
        true
      );
    }

    if (tridentTermInMonths) {
      termMonths = String(tridentTermInMonths);
      if (!termYears) {
        termYears = String(tridentTermInMonths / 12);
      }
    }

    setCalculator({
      ...calculator,
      autoCalculate: autoCalc,
      formFields: {
        ...calculator.formFields,
        purchasePrice: priceAmount,
        loanAmount: priceAmount,
        ...(formattedRate && { interestRate: formattedRate }),
        ...(termYears && { loanTermYears: termYears }),
        ...(termMonths && { loanTermMonths: termMonths })
      },
      result: {
        ...calculator.result
      }
    });

  }, [tridentLoanAmount, tridentTeaserRate, tridentTermInMonths]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calculator.autoCalculate && tridentTeaserRate) {
      const triggerAutoCalculate = async () => {
        await handleCalculate();
      };
      triggerAutoCalculate();
    }
  }, [calculator.autoCalculate, tridentTeaserRate]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeLastOccurrence = (str, char) => {
    const lastIndexOfL = str.lastIndexOf(char);
    return str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
  };

  const validatedownPayment = (processedValue) =>{
    const purchasePriceNumber = Number(calculator.formFields.purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number((processedValue).replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber > purchasePriceNumber) {
      return calculator.formFields.purchasePrice;
    }
    return processedValue;
  };


  const setCalculatorFormValue = (name, value) => {
    let processedValue = value;

    if (name === 'purchasePrice' || name === 'downPayment') {
      processedValue = (value.match(/\./g) || []).length > 1 ? removeLastOccurrence(processedValue, '.') : processedValue;
      const decimalPart = processedValue.indexOf('.') > -1 ? processedValue.substring(processedValue.indexOf('.')) : '';
      processedValue = formatPrice(processedValue.replace(/[^0-9.]/g, '').replace(/\..*/, ''), 'USD', 'en-US') + decimalPart.replace(/[^0-9.]/g, '');

      processedValue = !processedValue ? '$0' : processedValue;
      processedValue = name === 'downPayment' ? validatedownPayment(processedValue) : processedValue;

    } else {
      processedValue = value.replace(/[^0-9]/g, '');
    }
    let updatedFormValues = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: processedValue,
      },
    };

    const isLoanTermYears = name === 'loanTermYears';
    const isLoanTermMonths = name === 'loanTermMonths';
    if (isLoanTermYears || isLoanTermMonths) {
      const targetField = isLoanTermYears ? 'loanTermMonths' : 'loanTermYears';

      const value = isLoanTermYears ?
        (processedValue.length ? (parseInt(processedValue) * 12).toString() : '')
        :
        (processedValue.length
          ? parseInt(parseInt(processedValue) % 12) <= 6
            ? Math.floor(parseInt(processedValue) / 12).toString()
            : Math.ceil(parseInt(processedValue) / 12).toString()
          : '');

      updatedFormValues = {
        ...updatedFormValues,
        formFields: {
          ...updatedFormValues.formFields,
          [targetField]: value
        },
      };
    }

    setCalculator(updatedFormValues);
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setCalculatorFormValue(name, value);
  };

  const handleBlurMonthlyPaymentLoanAmount = async (event) => {
    const { name, value } = event.target;

    const processedValue = formatPriceWithDecimal(
      value.replace(/[^0-9.]/g, ''),
      'USD',
      'en-US',
      2
    );
    setCalculator({
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: processedValue,
      },
    });
    await handleCalculate();
  };

  const updateLoanAmount = (loanAmount) => {
    let updatedCalculator = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        loanAmount
      },
    };
    setCalculator(updatedCalculator);
  };

  const updateDownPayment = (downPayment) => {
    let updatedCalculator = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        downPayment
      },
    };
    setCalculator(updatedCalculator);
  };

  const handleCalculate = async () => {
    const blankLoanAmountOrMonthlyPayment = !purchasePrice?.replace(/ /g, '').length > 0;
    const blankLoanTermYears = !loanTermYears?.replace(/ /g, '').length > 0;
    const blankLoanTermMonths = !loanTermMonths?.replace(/ /g, '').length > 0;

    const formMissingFields =
      blankLoanAmountOrMonthlyPayment ||
      blankLoanTermYears ||
      blankLoanTermMonths;

    const updates = {
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: blankLoanAmountOrMonthlyPayment,
        loanTermYears: blankLoanTermYears,
        loanTermMonths: blankLoanTermMonths,
      },
    };

    if (!formMissingFields) {
      const rate = interestRate;
      const term = loanTermMonths;
      const loanAmountOrMonthlyPayment = calculator?.formFields?.loanAmount;

      setGenericEvent('loan calculation', 'calculate - click', 'monthly payment');
      const monthlyPayment = calculateMonthlyPrice(rate, term, loanAmountOrMonthlyPayment, false);
      updates.autoCalculate = false;
      updates.result = {
        ...calculator.result,
        monthlyPayment,
      };
    }

    setCalculator({
      ...calculator,
      ...updates,
    });
  };

  const clearErrors = async () => {
    setCalculator({
      ...calculator,
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: false,
        loanTermYears: false,
        loanTermMonths: false,
        interestRate: false,
      },
    });
  };

  const {
    showErrorOnCalculate,
    formFields: {
      purchasePrice,
      downPayment,
      loanAmount,
      loanTermYears,
      loanTermMonths,
      interestRate,
    },
    result: { monthlyPayment },
  } = calculator;

  return (
    <div className="private-calc-calculator-container">
      <div className="private-calc-calculator-sub-container">
        <div className="private-calc-calculator-body">
          <CalculatorForm
            handleInputChange={handleInputChange}
            handleBlurMonthlyPaymentLoanAmount={
              handleBlurMonthlyPaymentLoanAmount
            }
            updateLoanAmount={updateLoanAmount}
            updateDownPayment={updateDownPayment}
            clearErrors={clearErrors}
            purchasePrice={purchasePrice}
            downPayment={downPayment}
            loanAmount={loanAmount}
            monthlyPayment={monthlyPayment}
            loanTermYears={loanTermYears}
            loanTermMonths={loanTermMonths}
            tridentTeaserRate={tridentTeaserRate}
            showErrorOnCalculate={showErrorOnCalculate}
          />
          {shouldShowRightPanel && (
            <CalculatorSummary goToApplyNow={goToApplyNow} />
          )}
        </div>
      </div>
    </div>
  );
};

PrivateSellerCalculator.propTypes = {
  setGenericEvent: PropTypes.func.isRequired,
  tridentLoanAmount: PropTypes.number,
  tridentTeaserRate: PropTypes.number,
  tridentTermInMonths: PropTypes.number,
  goToApplyNow: PropTypes.func,
  showMonthlyCalculatorOnly: PropTypes.bool,
  showPreQualified: PropTypes.bool,
};

const CalculatorForm = ({
  handleInputChange,
  handleBlurMonthlyPaymentLoanAmount,
  clearErrors,
  updateLoanAmount,
  updateDownPayment,
  purchasePrice,
  downPayment,
  loanAmount,
  loanTermYears,
  loanTermMonths,
  showErrorOnCalculate,
  monthlyPayment
}) => {

  useEffect(() => {
    const purchasePriceNumber = Number(purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number(downPayment.replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber >= purchasePriceNumber){
      downPaymentNumber = purchasePriceNumber;
      downPayment = formatPriceWithDecimal(downPayment, 'USD', 'en-US', 2); // eslint-disable-line react-hooks/exhaustive-deps
      updateDownPayment(downPayment);
    }
    const loanAmount = formatPriceWithDecimal(purchasePriceNumber - downPaymentNumber, 'USD', 'en-US', 2);
    updateLoanAmount(loanAmount);

  }, [purchasePrice, downPayment]);

  return (
    <div className="private-calc-calculator-form" data-e2e="private-calc-calculator-form">
      <form>
        <fieldset className="form-fieldset" data-e2e="form-fieldset">
          <div className="calc-form-title" data-e2e="monthly-price-title">
           Monthly Price
          </div>
          <div className="calc-form-field" data-e2e="loan-amount-field">
            <label className="calc-form-label calc-real-loan-amount-label" data-e2e="loan-amount-label">
              Loan amount
            </label>
            <div className="calc-real-loan-amount" data-e2e="calc-real-loan-amount">
              {loanAmount}
            </div>
          </div>

          <div className="calc-form-field" data-e2e="purchase-price-field">
            <label className="calc-form-label" data-e2e="purchase-price-label" htmlFor="loan-amount-or-monthly-payment">
              Purchase price
            </label>
            <input
              className="calc-form-data input"
              type="text"
              id="loan-amount-or-monthly-payment"
              name="purchasePrice"
              data-e2e="purchasePrice"
              placeholder="Enter purchase price"
              onChange={handleInputChange}
              onFocus={clearErrors}
              onBlur={handleBlurMonthlyPaymentLoanAmount}
              value={purchasePrice}
              data-testid="loan-amount-or-payment-form"
            />
          </div>

          <div className="calc-form-field" data-e2e="down-payment-field">
            <label className="calc-form-label" data-e2e="down-payment-label" htmlFor="loan-downpayment">
              Down payment
            </label>
            <input
              className="calc-form-data input"
              type="text"
              id="loan-downpayment"
              name="downPayment"
              data-e2e="downPayment"
              placeholder="Enter Down payment"
              onChange={handleInputChange}
              onFocus={clearErrors}
              onBlur={handleBlurMonthlyPaymentLoanAmount}
              value={downPayment}
              data-testid="loan-downpayment-form"
            />
          </div>

          <div className="calc-form-loan-term-container" data-e2e="loan-term-in-years-field">
            <div className="calc-form-field">
              <label className="calc-form-label loan-amount-or-monthly-payment" data-e2e="loan-term-in-years-label" htmlFor="loan-term-years">
                Loan term in Years
              </label>
              <input
                className="calc-form-data input years"
                type="text"
                id="loan-term-years"
                name="loanTermYears"
                data-e2e="loanTermYears"
                placeholder="Enter term in Years"
                onChange={handleInputChange}
                onFocus={clearErrors}
                value={loanTermYears}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                data-testid="loan-term-years-form"
              />
              <div className={classNames('calc-free-text-input-error', {hidden: !showErrorOnCalculate.loanTermYears})} data-e2e="years-error-message">
                * Missing required field
              </div>
            </div>

            <div className="calc-or-text" data-e2e="calc-or-text">Or</div>

            <div className="calc-form-field" data-e2e="loan-term-in-months-field">
              <label className="calc-form-label" data-e2e="loan-term-in-months-label" htmlFor="loan-term-months">
                Loan term in Months
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-term-months"
                name="loanTermMonths"
                data-e2e="loanTermMonths"
                placeholder="Enter term in Months"
                onChange={handleInputChange}
                onFocus={clearErrors}
                value={loanTermMonths}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                data-testid="loan-term-months-form"
              />
              <div className={classNames('calc-free-text-input-error', {hidden: !showErrorOnCalculate.loanTermMonths})} data-e2e="months-error-message">
                * Missing required field
              </div>
            </div>
          </div>
          <div className="calc-montlhy-payment-container" data-e2e="calc-montlhy-payment-container">
            <span className="calc-montlhy-payment-amount" data-e2e="calc-montlhy-payment-amount">{monthlyPayment}*</span>/month
          </div>

          <div className="calc-form-instructions" data-e2e="instructions-field">
              The calculated monthly payment above is based on the current RATES APR and the loan term and loan amount that you entered.
            <br /><br />
              For more information on the APR rates, please visit our <a className="calc-boat-loans-link" data-e2e="calc-boat-loans-link" href="/boat-loans/calculator/" target="_blank">Boat Loan Calculator</a> page.
          </div>

        </fieldset>
      </form>
    </div>
  );
};

CalculatorForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleBlurMonthlyPaymentLoanAmount: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  updateLoanAmount: PropTypes.func.isRequired,
  purchasePrice: PropTypes.string,
  downPayment: PropTypes.string,
  loanAmount: PropTypes.string,
  loanTermYears: PropTypes.string,
  loanTermMonths: PropTypes.string,

  showErrorOnCalculate: PropTypes.shape({
    loanAmountOrMonthlyPayment: PropTypes.bool.isRequired,
    loanTermYears: PropTypes.bool.isRequired,
    loanTermMonths: PropTypes.bool.isRequired,
  }),
};

export const LeadSuccessNotification = (props) => {

  const onClose = () => {
    props.onClose();
  };

  return <div className="inline-prequalified-success" data-testid="inline-prequalified-success-toast">
    <div className="inline-prequalified-success-logo"></div>
    <div className="inline-prequalified-success-info" data-e2e="inline-prequalified-success-info">
      <div className="inline-prequalified-title" data-e2e="inline-prequalified-title">Your request has been sent!</div>
      A member of our Boat Trader financing team from Trident Funding will be in touch shortly.
    </div>
    <button className="modal-close" data-e2e="modal-close-button" data-testid="inline-close-prequalified-success" onClick={() => onClose()}>
      <span className="visuallyhidden">Close</span>
    </button>
  </div>;
};

const CalculatorSummary = (props) => {
  const trackPreQualify = () => {
    window.dataLayer.push(
      {
        'event': 'link_click',
        'action_type': 'click',
        'action_label': 'boat loan - getting started',
        'ga4': {
          page: {
            'detailed_page_name': TRACKING_BOAT_LOANS_CALCULATOR_DETAILED_PAGE_NAME,
            'section': TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SECTION,
            'subsection': TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SUB_SECTION,
            portal: TRACKING_BOAT_LOANS_PORTAL
          }
        }
      }
    );
  };

  return (
    <div className="calc-calculator-summary" data-e2e="calc-calculator-summary">
      <div className="summary-title" data-e2e="summary-title">Boat loans made easy!</div>
      <>
        <div className="summary-step" data-e2e="calculate-payment-step">
          <div className="summary-step-title" data-e2e="calculate-payment-step-title">
            <div className="summary-step-number" data-e2e="calculate-payment-step-number">1</div>
            <div className="summary-step-name" data-e2e="calculate-payment-step-name">Calculate Payment</div>
          </div>
          <ul className="calculate-payment-step-text">
            <li>Estimate your monthly payment with the calculator</li>
          </ul>
        </div>
        <div className="summary-step" data-e2e="pre-qualify-step">
          <div className="summary-step-title" data-e2e="pre-qualify-step-title">
            <div className="summary-step-number" data-e2e="pre-qualify-step-number">2</div>
            <div className="summary-step-name" data-e2e="pre-qualify-step-name">Pre-Qualify</div>
          </div>
          <ul className="summary-step-text" data-e2e="pre-qualify-step-text">
            <li>Get approved and on the water faster</li>
          </ul>
        </div>
        <div className="summary-step" data-e2e="finalize-step">
          <div className="summary-step-title" data-e2e="finalize-step-title">
            <div className="summary-step-number" data-e2e="finalize-step-number">3</div>
            <div className="summary-step-name" data-e2e="finalize-step-name">Finalize</div>
          </div>
          <ul className="summary-step-text" data-e2e="finalize-step-text">
            <li>We’ll compare over 15 marine lenders to get you the best rate with the best terms</li>
            <li>Finalize your loan within 24 hours</li>
          </ul>
        </div>

        <div className="summary-button-container summary-step" data-e2e="summary-button-container">
          <Action
            className="get-started-button" data-e2e="get-started-button"
            onClick={(e) =>{
              e.preventDefault();
              trackPreQualify();
              props.goToApplyNow();
            }}
            label="Get pre-qualified"
            size="large"
            stretch
          />
        </div>

      </>
    </div>
  );
};
