import React, { PureComponent } from 'react';
import LikedBoat from '../LikedBoat';
import ContactGroup from '../../containers/BoatDetails/ContactGroup';
import DetailsContact from '../../containers/BoatDetails/DetailsContact';
import { getTitle, getPrice } from '../../utils/listingHelper';
import Loading from '../LoadingPlaceholder';
import './styles.css';

export default class CarouselModalHeader extends PureComponent {

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    this.setState({});
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.state.stateDetailsContact === 'open' && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        stateDetailsContact: 'closed'
      });
    }
  }

  state = {
    stateDetailsContact: 'closed'
  }

  handleOnOpenContactlisting = () => {
    this.setState({
      stateDetailsContact: this.state.stateDetailsContact === 'open' ? 'closed' : 'open'
    });
  }

  renderModalHeader() {
    const {listing, isLoading, cookies, url, myboats = [], device, openModalDealerLocator} = this.props;
    return (
      <>
        <div className="modal-header-summary">
          <div className="modal-title"> {getTitle(listing)}</div>
          <div className="modal-summary">
            <div> {getPrice(listing)} </div>
            {listing.showSaveButton &&
              <div className="modal-like" data-e2e="modal-like">
                <LikedBoat listingId={listing.aliases['boat-trader']} myboats={myboats} imtId={listing.aliases.imt}
                  cookies={cookies} url={url} showText={false}/>
              </div>
            }
          </div>
        </div>
        <div className="contact-group-wrapper" ref={this.setWrapperRef} >
          <ContactGroup openModalDealerLocator={openModalDealerLocator} onOpenContact={this.handleOnOpenContactlisting} isOpening={ this.state.stateDetailsContact === 'closed'} device={device} listing={listing} showIcons={false}/>
          <DetailsContact
            externalOpen={this.state.stateDetailsContact === 'open'}
            disableSticky={true}
            isLoading={isLoading}
            listing={listing}
            disableSellerInfo={true}
            showAdditionalLeads={ true }
            inModal={ true }
          />
        </div>
      </>
    );
  }

  renderLoading() {
    return (
      <>
        <Loading type="text" />
        <Loading type="text" />
      </>
    );
  }

  render() {
    const { isLoading, listing } = this.props;
    return (
      <div data-testid="carousel-modal-header" className="modal-header-container">
        {isLoading || !listing.id ?
          this.renderLoading()
          :
          this.renderModalHeader(listing)
        }
      </div>
    );
  }
}
