import React, {useEffect, useState} from 'react';
import './enhanced-styles.css';
import './styles.css';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BANNER_ATTRIBUTES,
  LISTING_ENHANCED,
  LISTING_MANUFACTURER,
  LISTING_DEALER,
  DEFAULT_NO_BOAT_IMAGE
} from '../../constants/boats';
import { getTitle, getPrice, getLocation, addResizeParams, getListingType } from '../../utils/listingHelper';
import { setProductImpression, addLead, setGenericEvent, setListingClick } from '../../store/actions/dataLayer';
import { getListingReactUrlParams } from '../../utils/urlHelpers/boat';
import find from 'lodash/find';
import get from 'lodash/get';
import { calculateMonthlyPrice } from '../../utils/trident';
import * as utils from '../../store/utils';
import { likeBoat, unlikeBoat } from '../../store/actions';
import useBranchIoMetaTag from '../../utils/hooks/useBranchIoMetaTag';
import { BRANCH_EVENT, BRANCHIO_CONTENT_TYPES, branchIoEventsManager } from '../../utils/branchIoEventsManager';
import { generateTags } from '../../utils/tagsHelper';
import PreparedListing from '../LegacyListingResult/components/PreparedListing';
import { isFinanceable } from '../../utils/trident';
import { useDeviceViewport } from '../../utils/hooks/useDeviceViewport';

const ListingResultV2 = ({
  contactForm, listing, url = '', sponsorCriteria, addContactForm, myboats, userRef, position,
  dataLayer, dealerId, isEngine = false, tridentTeaserRate, lazyLoading,
  setProductImpression, addLead, likeBoat, unlikeBoat
}) => {
  const [liked, setLiked] = useState(false);
  const [queueLikedBoats, setQueueLikedBoats] = useState([]);

  const { isDesktop } = useDeviceViewport();
  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();

  const handleContactButtonClick = () => {
    if (!contactForm.form || contactForm.type !== 'contact') {
      prepareAddContactForm(
        'contact',
        'Contact Seller'
      );
    } else {
      handleFormButtonClose();
    }
  };

  const handleFormButtonClose = () => {
    addContactForm({
      type: '',
      form: undefined
    });
  };

  const prepareAddContactForm = (type, title, subtitle, hideComments, comment, analytics = true, connectionProperties) => {
    const isSponsored = get(listing, 'featureType.sponsored', false);
    const enhancemenTriggering = isSponsored ? sponsorCriteria : undefined;
    const formProps = {
      listing, url, type,
      title, subtitle, hideComments,
      comment, analytics, connectionProperties,
      enhancemenTriggering
    };
    addContactForm({
      type,
      form: formProps
    });
  };

  const getQueuedBoatsFromLocalStorage = () => JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];

  const getLikedState = (listingId) => {
    if (utils.isServer()) {
      return false;
    }
    if (myboats?.requireLogin) {
      const queuedBoats = getQueuedBoatsFromLocalStorage();
      setQueueLikedBoats(queuedBoats);
      return queuedBoats.includes(listingId);
    }
    return myboats?.listings
      ? (myboats.listings.filter(
        (boat) => { return parseInt(boat.listingId) === parseInt(listingId);}).length > 0
      )
      : false;
  };

  const queueLikedBoat = (listingId) => {
    const queuedBoats = JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];
    if (!queuedBoats.includes(listingId)) {
      queuedBoats.push(listingId);
      setLiked(true);
    } else {
      queuedBoats.splice(queuedBoats.indexOf(listingId), 1);
      setLiked(false);
    }
    localStorage.setItem('QUEUED_LIKED_BOATS', JSON.stringify(queuedBoats));
    window.dispatchEvent(new Event('storage'));
  };

  const forwardToLogin = () => {
    userRef?.current.onOpenModal();
  };

  const addBoatToMyBoats = (listingId) => {
    let data = {listingId: listingId};
    likeBoat(data);
  };

  const removeBoatFromMyBoats = (listingId) => {
    unlikeBoat(listingId);
  };

  const updateLikedState = ()=>setLiked(getLikedState(listing.aliases['boat-trader']));

  const handleLocalStorageChange = () => {
    const queuedBoatsFromLocalStorage = getQueuedBoatsFromLocalStorage();
    if (queueLikedBoats.length !== queuedBoatsFromLocalStorage.length) {
      updateLikedState();
    }
  };

  useEffect(() => {
    let listingType = getListingType(listing);
    let { id } = listing;
    const region = dataLayer?.region || undefined;
    const category = dataLayer?.category || undefined;
    const container = dataLayer?.container || undefined;
    const itemName = getTitle(listing);
    setProductImpression(id, listingType, position.position, position.page, container, region, category, undefined, itemName, listing.make);
    window?.permutiveHelper?.addListing && window?.permutiveHelper?.addListing(listing);

    updateLikedState();

    if (myboats?.requireLogin) {
      window.addEventListener('storage', handleLocalStorageChange);
      return () => window.removeEventListener('storage', handleLocalStorageChange);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => updateLikedState(), [myboats]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveListing = (state) => {
    const liked = state === 'on';
    if (liked) {
      fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.SAVE_BOAT);
    }
    if (myboats.requireLogin) {
      queueLikedBoat(listing.aliases['boat-trader']);
      forwardToLogin();
    } else {
      if (liked) {
        addBoatToMyBoats(listing.aliases['boat-trader']);
        setLiked(true);
        addLead(listing.id, 'save boat');
        branchIoEventsManager(BRANCH_EVENT.LIKE_BOAT, {
          sku: get(listing, 'id', ''),
          product_name: get(listing, 'model', ''),
          product_brand: get(listing, 'validMake', ''),
        });
      } else {
        removeBoatFromMyBoats(listing.aliases['boat-trader']);
        setLiked(false);
      }
    }
  };

  let listingType = getListingType(listing);
  const listingTypeForTracking = dealerId ? LISTING_DEALER : listingType;
  let title = getTitle(listing);
  let resize = get(listing, 'featureType.enhanced', false) ? {width: 400, height: 267} : {width: 300, height: 222};
  let location = getLocation(listing, false, false);
  let portalLink = getListingReactUrlParams(listing, isEngine);

  let listingAnchorParams = {
    'data-reporting-click-product-id': dealerId ? dealerId.split('-').pop() : listing.id,
    'data-reporting-click-listing-type': listingTypeForTracking,
    'data-reporting-rank': position.position,
    'data-reporting-page': position.page,
    ...(isDesktop && { target: '_blank' }),
    ...portalLink
  };

  const priceDiscount = get(listing, 'price.discount');
  const listingAttributes = get(listing,  'attributes', []);
  const bannerAttribute = find(listingAttributes, (a) => BANNER_ATTRIBUTES.includes(a));
  const contactClickType = bannerAttribute ? `${listingTypeForTracking} ${bannerAttribute}` : listingTypeForTracking;
  const specificationsLength = get(listing, 'specifications.dimensions.lengths.nominal.ft', 0);
  const monthlyPrice = calculateMonthlyPrice(tridentTeaserRate, get(listing, 'price.type.amount.USD') && get(listing, 'price.type.amount.USD') >= 50000 ? 240 : 180, get(listing, 'price.type.amount.USD'));
  const hiddenPrice = get(listing, 'price.hidden') || !listing.price?.type?.amount || listing.price?.type?.amount.USD <= 1;
  const price = get(listing, 'price.type.amount.USD');
  const boatYear = get(listing, 'year');
  const financeable = isFinanceable(price, boatYear);

  let listingMedia = get(listing, 'media', []);
  let images = listingMedia.filter((media) => {
    return media.mediaType === 'image';
  });
  let image = images[0];
  const listingClass = {classNames: {}};
  if (listingType === LISTING_MANUFACTURER){
    listingClass.classNames.oem = true;
  }
  else if (listingType === LISTING_ENHANCED){
    listingClass.classNames.enhanced = true;
  }
  else {
    listingClass.classNames.standard = true;
  }

  const getSellerValue = (listing, listingType) => {
    if (listingType === LISTING_MANUFACTURER){
      return (get(listing.owner, 'name', ''));
    }
    return `${location ? location + ' | ' : ''}${listing.owner?.rootName ? listing.owner.rootName : get(listing.owner, 'name', '')}`;

  };

  const listingProps = {
    onClick: () => {
      setListingClick(listing.id, listingTypeForTracking, '', '', title, position?.position, listing.make );
    },
    hyperlinkAttributes: listingAnchorParams,
    price: getPrice(listing),
    name: title,
    location: location,
    seller: getSellerValue(listing, listingTypeForTracking),
    like: listing.showSaveButton ? {
      onClick: saveListing,
      state: liked ? 'on' : 'off',
      on: 'on',
      off: 'off'
    } : {
      onClick: undefined
    },
    image: image ? addResizeParams(get(image, 'original.url', image.url), resize, image.date.modified, true, 'webp') : DEFAULT_NO_BOAT_IMAGE,
    imageAlt: title,
    imageLazyLoading: lazyLoading,
    contact: listingType === LISTING_ENHANCED ? {
      onClick: () => {
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.setAttribute('data-reporting-contact-click-product-id', listing.id);
        anchor.setAttribute('data-reporting-contact-click-boat-classname', listing.class);
        anchor.setAttribute('data-reporting-contact-click-make', listing.make);
        anchor.setAttribute('data-reporting-contact-click-length', specificationsLength);
        anchor.setAttribute('data-reporting-contact-click-listing-type', contactClickType);
        anchor.setAttribute('data-reporting-contact-click-state', listing.location.state);
        document.body.appendChild(anchor);
        anchor.click();

        handleContactButtonClick();

        return false;
      },
      text: 'Request Info'
    } : undefined,
    tags: generateTags(listing, { enhanced: listingType === LISTING_ENHANCED, standard: listingType !== LISTING_ENHANCED, oem: listingType === LISTING_MANUFACTURER }),
    monthlyPrice: monthlyPrice && !hiddenPrice && financeable ? {
      text: '$' + monthlyPrice + '/mo*',
      tooltip: {
        content: `Estimated monthly payment based on a 240-month loan at ${tridentTeaserRate}% APR.`
      }
    } : undefined,
    prevPrice: priceDiscount ? '↓ Price Drop' : undefined,
    logo: listing.owner?.logos?.enhanced?.replace(/^http:\/\//, 'https://'),
    logoAlt: 'Dealer Logo',
    ...listingClass,
    id: listing.id,
    hiddenPriceMessage: listingType === LISTING_MANUFACTURER ? 'Request a Price' : undefined,
    manufacturerListingText: listingType === LISTING_MANUFACTURER ? 'Manufacturer Listing' : undefined
  };
  return (<>
    <BranchIoMetaTagComponent />
    <li
      className={classnames('lib-card', {'premium': get(listing, 'featureType.enhanced')})}
      data-listing-id={listing.id}
      data-reporting-click-product-id={listing.id}
      data-reporting-click-listing-type={listingTypeForTracking}
    >
      <PreparedListing {...listingProps}></PreparedListing>
    </li>
  </>);
};

const mapStateToProps = (state) => {
  return {
    sponsorCriteria: get(state.app, 'data.sponsored.criteria'),
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false),
    userRef: state.app.userRef
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    setProductImpression,
    addLead,
    setGenericEvent,
    likeBoat, unlikeBoat
  }, dispatch)
)(ListingResultV2);
