import React, { PureComponent } from 'react';
import Slider from '../../Slider';
import { DEFAULT_NO_MAX_PRICE, SLIDER_MAX_VALUE, DEBOUNCE_TIMEOUT } from '../../../constants/boats';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import Switch from 'react-toggle-switch';
import Tooltip from 'react-tooltip-lite';
import Link from '../../SEO/Link';
import { getBreakpoint } from '../../../utils/commonHelper';
import 'react-toggle-switch/dist/css/switch.min.css';
import './styles.css';

class FilterPrice extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      min: props.min || '',
      max: props.max || '',
      dollarMinClass: '',
      dollarMaxClass: '',
      dragging: false,
      priceSelected: false,
      selectedPriceRevisedSince: props.selectedPriceRevisedSince ? props.selectedPriceRevisedSince : []
    };
  }

  updateMultiInput() {
    if (this.state.editing || !this.state.dragging) {
      this.setState({ max: this.props.max || '' });
    }
    if (this.state.editing || !this.state.dragging) {
      this.setState({ min: this.props.min || '' });
    }

    if (getBreakpoint() === 'mobile' || getBreakpoint() === 'tablet') {
      if (this.props.selectedPriceRevisedSince === this.state.selectedPriceRevisedSince) {
        this.setState({ selectedPriceRevisedSince: this.props.selectedPriceRevisedSince });
      } else if (this.props.selectedPriceRevisedSince === undefined && this.state.priceSelected) {
        this.setState({ selectedPriceRevisedSince: this.props.selectedPriceRevisedSince, priceSelected: false });
      } else if (!this.state.priceSelected &&
        this.props.selectedPriceRevisedSince &&
        this.state.selectedPriceRevisedSince &&
        this.state.selectedPriceRevisedSince.length === 1 &&
        this.props.selectedPriceRevisedSince.indexOf(this.state.selectedPriceRevisedSince[0])) {
        this.setState({ priceSelected: true });
      }
    } else if (getBreakpoint() === 'desktop' && this.props.selectedPriceRevisedSince !== this.state.selectedPriceRevisedSince) {
      this.setState({ selectedPriceRevisedSince: this.props.selectedPriceRevisedSince });
    }

    this.handleDollarClass();
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  handleSliderMaxValue(val) {
    if (val === DEFAULT_NO_MAX_PRICE) {
      return SLIDER_MAX_VALUE;
    }
    return val;
  }

  handleSliderDrag = (handle, val) => {
    if (this.state.editing) {
      return;
    }
    if (handle === 'min') {
      this.setState({ min: val.toString(), dragging: true });
    } else {
      this.setState({ max: this.handleSliderMaxValue(val.toString()), dragging: true });
    }
  }

  handleSliderDragEnd = (handle, val) => {
    if (!this.state.dragging) {
      return;
    }
    this.setState({ dragging: false });
    if (handle === 'min') {
      if (this.props.tracking) {
        this.debounceTracking({ ['price']: { 'min': val } });
      }
      this.props.handleDataChange('price', { min: val.toString(), max: this.state.max });
    } else {
      if (this.props.tracking) {
        if (!val || val === DEFAULT_NO_MAX_PRICE) {
          this.debounceTrackingRemove('maximum price removed');
        } else {
          this.debounceTracking({ ['price']: { 'max': val } });
        }
      }
      this.props.handleDataChange('price', { min: this.state.min, max: this.handleSliderMaxValue(val.toString()) });
    }
  }

  handleDataChange(type, value) {
    if (type === 'min') {
      if (this.props.tracking) {
        if (!value) {
          this.debounceTrackingRemove('minimum price removed');
        } else {
          this.debounceTracking({ ['price']: { 'min': value } });
        }
      }
      this.props.handleDataChange('price', { min: value, max: this.state.max });
    } else {
      if (this.props.tracking) {
        if (!value) {
          this.debounceTrackingRemove('maximum price removed');
        } else {
          this.debounceTracking({ ['price']: { 'max': value } });
        }
      }
      this.props.handleDataChange('price', { min: this.state.min, max: value });
    }
  }

  debounceTracking = debounce((value) => this.props.tracking.facetAdded(value), DEBOUNCE_TIMEOUT);
  debounceTrackingRemove = debounce((value) => this.props.tracking.facetRemoved(value), DEBOUNCE_TIMEOUT);

  handleDollarClass() {

    if (parseInt(this.state.min)) {
      this.setState({ dollarMinClass: 'dollar' });
    } else {
      this.setState({ dollarMinClass: '' });
    }

    if (this.state.max) {
      if (this.state.max === (SLIDER_MAX_VALUE).toString()) {
        this.setState({ dollarMaxClass: '' });
      } else {
        this.setState({ dollarMaxClass: 'dollar' });
      }
    } else {
      this.setState({ dollarMaxClass: '' });

    }
    return;
  }

  handleTogglePriceRevisedSince = () => {
    const PRICE_DROP_RANGE = '30';
    if (includes(this.state.selectedPriceRevisedSince, PRICE_DROP_RANGE)) {
      if (this.props.tracking) {
        this.debounceTrackingRemove('price reduce price removed');
      }
      this.setState({
        selectedPriceRevisedSince: []
      }, () => {
        this.props.handleDataChange('priceRevisedSince', undefined);

      });
    }
    else {
      if (this.props.tracking) {
        this.debounceTracking({ ['priceRevisedSince']: PRICE_DROP_RANGE});
      }
      this.setState({
        selectedPriceRevisedSince: [PRICE_DROP_RANGE]
      }, () => {
        this.props.handleDataChange('priceRevisedSince', PRICE_DROP_RANGE);
      }
      );


    }
  }

  hasValidDateRange(priceRevisedSince) {
    let validDates = ['-1', '-3', '-7', '-14', '-30']; // From 1 to 30 days of price reduction.;
    return priceRevisedSince.filter(x => validDates.indexOf(x.value) > -1).length > 0;
  }

  onFocusPrice = () => {
    this.setState({editing: true});
  }

  onBlurPrice = () => {
    this.setState({editing: false});
  }

  onChangePriceMin = (value) => {
    this.handleDataChange('min', value.target.value);
  }

  onChangePriceMax = (value) => {
    this.handleDataChange('max', value.target.value);
  }

  render() {
    let { priceRevised = [], hidePriceDrop } = this.props;
    let valid = this.hasValidDateRange(priceRevised);
    let selected = includes(this.state.selectedPriceRevisedSince, '30');

    return (
      <div className="search-filter price">
        <div className="slider-container">
          <Slider
            min={0}
            max={parseInt(DEFAULT_NO_MAX_PRICE)}
            initialMin={'0'}
            initialMax={this.props.initialMax || DEFAULT_NO_MAX_PRICE.toString()}
            value={[parseInt(this.state.min) || 0, parseInt(this.state.max) || parseInt(DEFAULT_NO_MAX_PRICE)]}
            marks={{ 0: ' ' }}
            step={1000}
            onChange={this.handleSliderDrag}
            onAfterChange={this.handleSliderDragEnd}
            handle={this.props.handle || ''}
          />
        </div>
        <div className="search-filter-group">
          <div className={this.state.dollarMinClass}>
            <input
              type="number"
              data-e2e="minimum-price"
              className="price-min"
              placeholder={'No Min'}
              value={this.state.min}
              onFocus={this.onFocusPrice}
              onBlur={this.onBlurPrice}
              onChange={this.onChangePriceMin}
              title="Minimum price"
            />
          </div>
          <span>to</span>
          <div className={this.state.dollarMaxClass}>
            <input
              type="number"
              data-e2e="maximum-price"
              className="price-max"
              name="isGoing"
              placeholder={'No Max'}
              value={this.state.max === SLIDER_MAX_VALUE ? '' : this.state.max}
              onFocus={this.onFocusPrice}
              onBlur={this.onBlurPrice}
              onChange={this.onChangePriceMax}
              title="Maximum price"
            />
          </div>
        </div>
        {!hidePriceDrop && <div className="search-filter-group">
          <Link className="model-link price-reduction">Price Drop
            <Tooltip
              content="Boats with price drops within last 30 days"
              useHover={true}
              eventOn="onClick"
              eventOff="onClick"
              className="tooltip"
            >
              <i className="material-icons">info</i>
            </Tooltip>
          </Link>
          <Switch
            onClick={this.handleTogglePriceRevisedSince}
            on={selected}
            className={`${(valid || selected) ? 'enabled' : 'disabled'} toggleButton`}
            enabled={valid || selected}
          />
        </div>}
      </div>
    );
  }
}

export default FilterPrice;
