import React, {useContext} from 'react';
import DetailsSection from '../DetailsSection';
import {BDPContext} from '../MainContent/BoatDetailsPage';
import CollapsibleDetailsSection from '../DetailsSection/CollapsibleDetailsSection';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';

const BDPDetailsSection = ({
  isWorking, listing, myboats, cookies, url, detailsState,
  adParams, onClickRequestInformation, breakpoint,
  displayModalPreQualified, shouldShowFinanceSummary}) => {

  const {tridentValues, isLeadSubmitted, boatLoansSection} = useContext(BDPContext);
  const {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate} = tridentValues;
  const { featureFlagBDPPhase3 } = useFeatureFlags();

  if (featureFlagBDPPhase3) {
    return (
      <CollapsibleDetailsSection
        url={url}
        myboats={myboats}
        cookies={cookies}
        listing={listing}
        adParams={adParams}
        isLoading={isWorking}
        breakpoint={breakpoint}
        isLeadSubmitted={isLeadSubmitted}
        boatLoansSection={boatLoansSection}
        tridentLoanAmount={tridentLoanAmount}
        tridentTeaserRate={tridentTeaserRate}
        tridentTermInMonths={tridentTermInMonths}
        showModalPreQualified={displayModalPreQualified}
        onClickRequestInformation={onClickRequestInformation}
        openPaymentCounter={detailsState.openPaymentCounter}
        openPaymentSection={detailsState.openPaymentSection}
        openBoatLoansCounter={detailsState.openBoatLoansCounter}
        openBoatLoansSection={detailsState.openBoatLoansSection}
      />
    );
  }

  return (<DetailsSection
    url={url}
    myboats={myboats}
    cookies={cookies}
    listing={listing}
    adParams={adParams}
    isLoading={isWorking}
    breakpoint={breakpoint}
    isLeadSubmitted={isLeadSubmitted}
    boatLoansSection={boatLoansSection}
    tridentLoanAmount={tridentLoanAmount}
    tridentTeaserRate={tridentTeaserRate}
    tridentTermInMonths={tridentTermInMonths}
    shouldShowFinanceSummary={shouldShowFinanceSummary}
    showModalPreQualified={displayModalPreQualified}
    onClickRequestInformation={onClickRequestInformation}
    openPaymentCounter={detailsState.openPaymentCounter}
    openPaymentSection={detailsState.openPaymentSection}
    openLocationCounter={detailsState.openLocationCounter}
    openLocationSection={detailsState.openLocationSection}
    openBoatLoansSection={detailsState.openBoatLoansSection}
    openBoatLoansCounter={detailsState.openBoatLoansCounter}
  />);
};

export default BDPDetailsSection;
