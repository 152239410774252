import * as storage from '../../utils/storage';
import { TRIDENT_SESSION_STORAGE_KEYS } from '../trident/constants';

const getMarketingParamsFromURL = () => {
  let params = {};
  let urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((value, key) => {
    if (TRIDENT_SESSION_STORAGE_KEYS.has(key.toLowerCase())) {
      params[key] = value;
    }});
  return params;
};


const storeMarketingParameters = (marketingParams) => {
  for (let key in marketingParams) {
    storage.setSessionItem(key, marketingParams[key]);
  }
};
const appendMarketingParamsToURL = (marketingParams) => {
  let urlParams = new URLSearchParams(window.location.search);

  for (let key in marketingParams) {
    if (!urlParams.has(key)) {
      urlParams.append(key, marketingParams[key]);
    }
  }

  let newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState({}, '', newUrl);
};

export const getMarketingParamsFromSessionStorage = () =>{
  let marketingParams = {};
  if (typeof window !== 'undefined') {
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if (TRIDENT_SESSION_STORAGE_KEYS.has(key.toLowerCase())) {
        marketingParams[key] = storage.getSessionItem(key);
      }
    }
  }
  return marketingParams;
};

export const checkMarketingParams = () =>{
  const marketingParamsFromStorage = getMarketingParamsFromSessionStorage();
  if (Object.keys(marketingParamsFromStorage).length !== 0){
    appendMarketingParamsToURL(marketingParamsFromStorage);
  }
  const marketingParamsFromURL = getMarketingParamsFromURL();
  if (Object.keys(marketingParamsFromURL).length !== 0){
    storeMarketingParameters(marketingParamsFromURL);
  }
};


export const overrideMarketingParams = (ctaLink, marketingParams) => {

  const [linkUrl, linkParams] = ctaLink.split('?');
  let urlParams = new URLSearchParams(linkParams);
  for (let key in marketingParams) {
    if (!urlParams.has(key)) {
      urlParams.append(key, marketingParams[key]);
    } else {
      urlParams.set(key, marketingParams[key]);
    }
  }
  return `${linkUrl}?${urlParams.toString()}`;
};
